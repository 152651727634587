export const h = {
    navbar: {
        home: "HOME",
        product: "PRODUCT",
        application: "APPLICATION",
        support: "SUPPORT",
        contact: "CONTACT",
        driver_download: "DOWNLOAD CENTER"
    },
    footer: {
        address: "Address",
        address_t1: "Star Engineering & Consulting",
        address_t2: "(Shanghai) Co., Ltd.",
        address_t3: "Room 6H, 6I, Crystal Century Tower,",
        address_t4: "No. 567 Wei Hai Road, Jing An District",
        address_t5: "200041 Shanghai",
        contact: "Contact",
        company: "Company",
        company_t1: "About Us",
        company_t2: "Contact",
        company_t3: "Wechat",
        legal: "Legal",
        legal_t1: "Site Notice",
        legal_t2: "Data Protection",
        legal_t3: "General Terms"
    },
    home: {
        title: "The Memorable Moments of Expo",
        subtitle: "Star Engineering & Consulting (Shanghai) Co., Ltd. " +
            "at Automotive Testing Expo China 2023",
        learn_more: "LEARN MORE",
        text_title: "Look into the future of vehicle electronics and network technology",
        text_subtitle: "Automotive Electronics & \n" +
            "Solutions",
        about_1: "ABOUT",
        about_2: "What are our products",
        about_3: "Focusing on the automotive industry, STAR Electronics provides " +
            "customers with customized software and hardware, or a variety of standard" +
            " and specific tools, for applications in simulation technology, measurement " +
            "and control technology, and energy management technology.",
        contact_1: "TEAM",
        contact_2: "What we do",
        contact_3: "STAR China provides you with a full range of STAR Electronics products. " +
            "Our team will recommend the best solution for your needs, providing the highest " +
            "quality products while ensuring a high level of service.",
    },
    product: {
        detail: "DETAILS",
        compare: "Compare",
        subtitle: "The FlexDevice product family\\n" +
            "Vehicle networking is an increasingly important topic: More and more components within a\\n" +
            "vehicle are interconnected. STAR COOPERATION® makes developing your vehicle electronics\\n" +
            "easier!\\n" +
            "Benefit from our custom fit software and hardware for complex networking technology.",
        title: "Tools for complex vehicle networking",
        device: "Flagship Multifunctional Bus Control Unit",
        interface: "Multifunctional Bus Interface",
        switch: "Automotive Ethernet Switch",
        media: "Automotive/Standard Ethernet Bidirectional Converter",
        system: "Multifunction Bus Control Units",
        software: "Multifunctional Automotive Bus Development Software",
    },
    application: {
        title: "Application Field and Expertise",
        title_detail: "We develop, design and manufacture the right tools for your project requirements." +
            "            From rest bus simulation, signal manipulation, gateway configuration and data" +
            "            visualization to data logging, our experts support you with software and hardware" +
            "            in highly complex vehicle electronics and network technology.",
        small_title: "",
        big_title: "Application Support",
        discover: "Discover more & Show Devices",
        text_1: "Rest Bus Simulation",
        text_2: "Gateway",
        text_3: "Signal Manipulation",
        text_4: "Logging",
        text_5: "User Function"
    },
    support: {
        title: "Technical Support",
        title_detail: "We save your time and budget with our solution and service",
        small_title: "OUR TEAM",
        big_title: "Application Support",
        text_1: "Hardware/Software Guidance \\n Technical Support",
        text_2: "Academy/Training",
        text_3: "User Manuals",
        text_4: "Driver Download",
    },
    contact: {
        title: "Get Support",
        title_detail: "Please provide your needs and questions and we will provide suitable solutions. You can contact us by phone, email or \n" +
            "follow us on WeChat",
        card_1: "OUR OFFICES",
        card_2: "Star Engineering & Consulting (Shanghai) Co., Ltd.\\n" +
            "              Room 6H, 61, Crystal Century Tower, No. 567 Wei Hai Road, Jing An District\\n" +
            "              200041 Shanghai",
        card_3: "WORKING HOURS",
        card_4: "9AM - 5PM, Mon to Fri",
        card_5: "CONTACT",
        card_6: "sales-ee@star-cooperation.cn",
        card_7: "+86 021-8018-3540",
        card_8: "WHAT WE ARE BEST AT",
        card_9: "Our Services",
    },
    gateway: {
        text_1: "Gateway",
        text_2: "Do you want to test your ECU in a real environment?",
        text_3: "With different communication interfaces, network description formats or development levels?",
        text_4: "We offer you solutions for the integrations of ECUs into an existing vehicle at various developmental\n" +
            "            stages. The FL3X Device is integrated between the ECU and your prototype vehicle.",
        text_5: "Here it acts as an intermediary between the current system and the new ECU. The most complex network\n" +
            "            structures or individual network nodes can be mapped with a FL3X Device in combination with the\n" +
            "            configuration\n" +
            "            software FL3X config.",
        text_6: "SHOW DEVICES",
    },
    logging: {
        text_1: "Logging",
        text_2: "Anyone who wants to record vehicle data during a test drive needs a data logger.",
        text_3: "These don't always have to be large and expensive measuring and data recording systems",
        text_4: "Miniaturized data loggers also be useful tools, for vehicle safety measurements,\n" +
            "            for example. You can record data with our FL3X Devices.",
        text_5: "For this purpose a FL3X Device has up to two (one internal and one external) microSD card\n" +
            "            slots. During your test drive, you can thus record your actual vehicle communication. The\n" +
            "            amount of data can be kept very low by using event-driven logging.",
        text_6: "SHOW DEVICES",
    },
    restbus: {
        text_1: "Restbus Simulation",
        text_2: "Do you want to test a new control unit?",
        text_3: "This is where restbus simulation can help you",
        text_4: "Modern vehicles are becoming increasingly complex - and the number of ECUs is\n" +
            "            is rising steadily. Therefore, restbus simulation is becoming more and more\n" +
            "            important.",
        text_5: "With our devices, you can carry out restbus simulations with ease. FL3X\n" +
            "            Device enables you to realistically simulate and test your ECUs in interaction with\n" +
            "            other components, or to check their functionality within the entire vehicle network.",
        text_6: "Are other ECUs missing in your network? No problem! Here, too, our FL3X Device is the\n" +
            "            solution. In combination with our configuration software FL3X config, you can quickly\n" +
            "            and conveniently create your own restbus simulations: from the straightforward simulation\n" +
            "            of individual network nodes to the simulation of complex networks.",
        text_7: "From now on, test your ECUs inexpensively in a digital twin.\n" +
            "            Without the need for a real prototype vehicle: expensive, long, and risky test drives\n" +
            "            are no longer necessary!",
        text_8: "SHOW DEVICES",
    },
    signal: {
        text_1: "Signal Manipulation",
        text_2: "Crucial functions of many ECUs only become apparent when they are tested in extreme\n" +
            "            situations.",
        text_3: "With a FL3X Device, you can manipulate all data and therefore simulate extreme\n" +
            "            situations. A real test drive - which is difficult to simulate and often dangerous\n" +
            "            - can, of course, be activated and adjusted during runtime. Test your ECUs virtually\n" +
            "            and under reproducible conditions.",
        text_4: "Connecting the control unit + loading and manipulating the database \n" +
            "            - safe and successful testing!",
        text_5: "SHOW DEVICES",
    },
    user: {
        text_1: "User Function",
        text_2: "The functions provide by a FL3X Device are not enough for you?",
        text_3: "No problem - extend them and integrate your own code into our application. Unique use\n" +
            "            cases that meet your individual requirements can be integrated quickly and easily. In\n" +
            "            addition, you can also integrate the newly created software functions of your ECU directly\n" +
            "            into the FL3X Device and ensure their functionality even before the hardware availability.",
        text_4: "Connecting the ECU + loading database and integrating your own code = individual and\n" +
            "            successful testing!",
        text_5: "SHOW DEVICES",
    },
    training: {
        title_1: "Networking technology \n" +
            "              thoroughly and \n" +
            "              competently taught",
        title_2: "With the FlexAcademy",
        title_3: "Benefit from the many years of experience that our STAR® experts \n" +
            "              have and rely upon our knowledge! Find the right FlexTraining for \n" +
            "              your requirements at our FL3X Academy or get practical support \n" +
            "              through our how-to videos.",
        subtitle_1: "Become a networking technology expert!",
        subtitle_2: "Benefit from our completely new training concept",
        subtitle_3: "In the FL3X Training FL3X Config, we teach you all the functions of the current software version\n" +
            "            step by step. You can also learn all the basics in our FL3X Training SOME/IP & Ethernet workshop\n" +
            "            and benefit from our expert knowledge.",
        subtitle_4: "Book now to secure your FL3X Training, either online or on-site at your location.",
        subtitle_5: "Your advantage:",
        subtitle_6: "Direct user support",
        subtitle_7: "Less time needed when we visit you on-site or meet online",
        subtitle_8: "Training content customized for you",
        subtitle_9: "Expertise provided by our STAR® experts",
        text_1: "No matter what task you're dealing with - whether it's about restbus simulation, rapid\n" +
            "              prototyping, gateway nodes or signal manipulation -FL3X Config has the right solution. This\n" +
            "              training will provide you with all the basics as well as expert tips and tricks to easily\n" +
            "              and effectively use FL3X Config in any situation.",
        text_2: "Make your communication future-proof! In our new SOME/IP & Ethernet training course, we will provide you with all the technical\n" +
            "              knowledge you need. Be it at STAR® in Germany, at your premises, or online - choose the\n" +
            "              best option for you.",
        text_3: "In our new CAN training course, we will provide you with all the technical knowledge you\n" +
            "              need. Be it at STAR® in Germany, at your premises, or online - choose the best option for you.",
        text_4: "In our new LIN & SENT training course, we will provide you with all the technical knowledge you\n" +
            "              need. Be it at STAR® in Germany, at your premises, or online - choose the best option for you.",
        text_5: "In our new FlexRay training course, we will provide you with all the technical knowledge you\n" +
            "              need. Be it at STAR® in Germany, at your premises, or online - choose the best option for you.",
        more: "Learn More",
    },
    user_manuals: "User Manuals",
    driver: {
        text_1: "Driver Download",
        text_2: "Download the free driver packages our FL3X product family in their current version",
        text_3: "A description which hardware type supports which components can be found\n" +
            "          in FL3X_driverpackage.zip in the document \"FL3X_driver_package_info.pdf\"",
        text_4: "A description which hardware type supports which components can be found\n" +
            "          in fl3x_driverpackage.zip in the document \"FL3X_driver_package_info.pdf\"",
        text_5: "A description which hardware type supports which components can be found\n" +
            "          in fl3x_driverpackage.zip in document \"FL3X_driver_package_info.pdf\".",
        text_6: "A description which hardware type supports which components can be found\n" +
            "          in fl3x_driverpackage.zip in document \"FL3X_driver_package_info.pdf\".",
        history: "Previous Versions"
    },
    can: {
        text_1: "We provide you with the necessary specialist knowledge! Learn everything about the Controller Area\n" +
            "      Network (CAN) in our 1-day event. You will then receive the documents shown in English. You can also\n" +
            "      decide where your individual training will take place: at the STAR® location in Germany, at your site\n" +
            "      or online.",
        text_2: "Training Content",
        text_3: "CAN Overview with respect to Automotive Domain",
        text_4: "CAN Physical layer",
        text_5: "CAN Bus (CAN High & CAN Low)",
        text_6: "CAN Bus Logic (Dominant & Recessive)",
        text_7: "CAN Bus differential voltage",
        text_8: "CAN Transceiver & CAN Controller",
        text_9: "CAN Bus Termination",
        text_10: "CAN Connectors",
        text_11: "CAN-based Evolution",
        text_12: "CAN Data Transmission Data link layer",
        text_13: "Bitwise Arbitration",
        text_14: "Standard/Extended Classical CAN/CAN FD Frame Formats",
        text_15: "CAN Frame types",
        text_16: "Non-Return to Zero (NRZ) with Bit-Stuffing in CAN/CAN-FD",
        text_17: "CAN Bit Timing",
        text_18: "Bit Time",
        text_19: "Time Quanta",
        text_20: "Bit Time Segmentation",
        text_21: "Edge Phase Error",
        text_22: "CAN Error Handling",
        text_23: "Error Counters (TEC & REC)",
        text_24: "Error States (Error Active & Error passive & Bus Off)",
        text_25: "Error Confinement",
        text_26: "Error detection mechanisms",
        text_27: "CAN XL Basics",
        text_28: "CAN XL Overview with respect to Automotive domain",
        text_29: "CAN Protocol Milestones",
        text_30: "CAN XL in OSI reference Model",
        text_31: "CAN XL core properties",
        text_32: "CAN XL Data Transmission/CAN XL Error Handling",
        text_33: "CAN XL Data Frame Format",
        text_34: "IP Concept on CAN XL",
        text_35: "CAN XL integration to an Ethernet network",
        text_36: "Embedded IP packets into CAN XL Frames",
        text_37: "CAN XL Security (CANsec)",
        text_38: "CAN XL Error Handling",
        text_39: "CAN XL Error Detection mechanisms",
        text_40: "Error Mechanisms",
        text_41: "Error Types",
        text_42: "Format Checks",
        text_43: "CRC Results",
        text_44: "Bit Monitoring",
        text_45: "Your advantages",
        text_46: "Short 1-day training",
        text_47: "Lots of practical exercises",
        text_48: "Suitable for beginners as well as advanced users",
        text_49: "Solution strategies of internal mechanisms of action for defining complex problems",
        text_50: "Explanation of internal mechanisms of action for defining complex problems",
        text_51: "Interested?",
        text_52: "Then take a look at the detailed description of our training courses including the agenda. Or simply contact\n" +
            "      us directly for your individual FL3X Training offer.",
        text_53: " DOWNLOAD DESCRIPTION AND AGENDA",
    },
    config: {
        text_1: "The FL3X Config software provide solutions for even the most complex of technical networking\n" +
            "      challenges. No matter what task you're dealing with -whether it's to do with restbus simulation,\n" +
            "      rapid prototyping, gateway nodes or signal manipulation - FL3X Config has the right solution.\n" +
            "      FL3X Config is intuitive and can be adapted to your own use case.",
        text_2: "This training will provide all the basics, as well as tips and tricks to easily and effectively\n" +
            "      use FL3X Config* in any situation. Using a step-by-step approach, we'll show you the\n" +
            "      easy way to get started with our tool. Drawing on your exclusive expert knowledge, we will guide\n" +
            "      you deep into our user software."
    },
    ray: {
        text_1: "We provide you with the necessary specialist knowledge! Learn everything about the FlexRay\n" +
            "      in our 1-day event. You will then receive the documents shown in English. You can also\n" +
            "      decide where your individual training will take place: at the STAR® location in Germany,\n" +
            "      at your site or online.",
        text_2: "Training content FlexRay",
        text_3: "FlexRay Overview with respect to Automotive Domain",
        text_4: "FlexRay Physical layer",
        text_5: "Topology variants",
        text_6: "Network component",
        text_7: "- Cables, connectors, termination",
        text_8: "Bus Driver",
        text_9: "Active Star",
        text_10: "Communication controller",
        text_11: "Bus Guardian",
        text_12: "FlexRay protocol",
        text_13: "Components of a FlexRay ECU",
        text_14: "Transfer structure",
        text_15: "FlexRay access structure",
        text_16: "Frame format",
        text_17: "Frame coding",
        text_18: "Synchronization",
        text_19: "FlexRay wakeup",
        text_20: "FlexRay startup",
        text_21: "FlexRay - Related Projects",
        text_22: "AUTOSAR",
        text_23: "FIBEX",
        text_24: "Your advantages",
        text_25: "Short 1-day training",
        text_26: "Lots of practical exercises",
        text_27: "Suitable for beginners as well as advanced users",
        text_28: "Interested?",
        text_29: "Then take a look at the detailed description of our training courses including the agenda.\n" +
            "      Or simply contact us directly for your individual FL3X Training offer.",
        text_30: "DOWNLOAD DESCRIPTION AND AGENDA",

    },
    lin: {
        text_1: "We provide you with the necessary specialist knowledge! Learn everything about the LIN & SENT in our " +
            "1-day event. You will then receive the documents shown in English. You can also decide where your " +
            "individual training will take place: at the STAR® location in Germany, at your site or online.",
        text_2: "LIN Overview with respect to Automotive Domain",
        text_3: "LIN Physical layer",
        text_4: "LIN Bus Logic (Dominant & Recessive)",
        text_5: "LIN Bus voltage",
        text_6: "LIN Transceiver",
        text_7: "LIN Serial Interface",
        text_8: "LIN Connectors",
        text_9: "LIN Bus Error Analysis in Oscilloscope",
        text_10: "LIN Data Transmission",
        text_11: "LIN Master/Slave Tasks",
        text_12: "The Principle of LIN Schedule Table",
        text_13: "LIN Frame Format",
        text_14: "LIN Frame Types",
        text_15: "LIN Frame Header",
        text_16: "LIN Frame Response",
        text_17: "LIN Communication Workflow",
        text_18: "PID/Check-sum Calculation in the LIN Data frame format",
        text_19: "LIN Error Handling",
        text_20: "LIN Error detection mechanisms",
        text_21: "SENT Overview with respect to Automotive Domain",
        text_22: "SENT Physical layer",
        text_23: "SENT Physical Layer Topology",
        text_24: "SENT Connectors",
        text_25: "Bus Wiring Harshness and ECU Connectors",
        text_26: "Oscilloscope traces of SENT Fast/Slow Channel transmission",
        text_27: "SENT Data Transmission",
        text_28: "Basic Definition for SENT Terminology",
        text_29: "SENT Fast Channel Data Frame Format",
        text_30: "Short Serial Message",
        text_31: "Enhanced Serial Message",
        text_32: "Data Encoding Method for SENT Formats"
    },
    some: {
        text_1: "Make your communications future-proof! In our new training course SOME/IP & Ethernet " +
            "we will provide you with all the technical knowledge you need. Whether in German or English, " +
            "you can choose in which language the training takes place. You will receive the documents in English. " +
            "You can also decide where your individual training take place: at the STAR® site in Germany, " +
            "at your premises, or online.",
        text_2: "Together we will provide a basic understanding of Ethernet so that you can recognise and " +
            "avoid some of the pitfalls you might encounter when dealing with new communication technologies. " +
            "The OSI model consists of seven levels, each with precisely defined tasks and clear interfaces " +
            "to the next level. This model ensures that individual layers can be replaced in ideal scenarios.",
        text_3: "Ethernet is used in vehicles for various functions, such as diagnostics, measurement and calibration. " +
            "Control units for electric vehicles and charging stations all communicate with each other throughout " +
            "the complete charging cycle.",
        text_4: "Using Ethernet as a bus system in automated and autonomous vehicles has become indispensable.",
        text_5: "We can shed light on these and other questions during our FlexTraining",
        text_6: "Rapid developments in vehicle communication allows new functions to be integrated and paves " +
            "the way for the future.",
        text_7: "Today, SOME/IP is found in various systems, such as Infotainment consoles, Advanced Driver " +
            "Assistance Systems (ADAS), cameras and much more as a result of the various advantages it " +
            "offers over classical communication.",
        text_8: "We look at these benefits as a whole.",
        text_9: "Service Oriented Architecture (SOA)",
        text_10: "Service-Oriented Communication vs Signal-Based Communication",
        text_11: "SOME/IP Integration",
        text_12: "Client/Server Model",
        text_13: "SOME/IP Service Discovery Message (Message Header, Message Entries, Message Options)",
        text_14: "SOME/IP Service Discovery Timings",
        text_15: "SOME/IP Events/Fields/Methods",
        text_16: "SOME/IP Serialization/Deserialization",
        text_17: "SOME/IP Message Container",
        text_18: "SOME/IP TP-Transport Protocol",
        text_19: "Securing SOME/IP via SecOC (Secure Onboard Communication)",
        text_20: "Divided into theoretical and practical sections"
    },
    compare: {
        introduction: "Watch a guided tour of FlexDevices",
        details: "DETAIL",
        tech: "Technical Features",
        software_support: "Software Support",
        electric_support: "Electrical Characteristics",
        physical_support: "Physical Properties",
        environment: "Environmental Conditions",
        device: {
            pro: {
                text_1: "2*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "2 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up To 8 FlexRay Controllers",
                text_7: "Up To 10 FlexRay Channels",
                text_8: "Up To 20 CAN-HS Bus Interfaces",
                text_9: "Up To 20 CAN-FD Bus Interfaces",
                text_10: "Wake-up/Sleep Mode",
                text_11: "Wake-up Possible Via FlexRay Or CAN",
                text_12: "Up To 10 100 MBit/s Ethernet Bus Interfaces",
                text_13: "Up To 10 100BASE-T1 Bus Interfaces",
                text_14: "Up To 7 1000BASE-T1 Bus Interfaces",
                text_15: "1 GBit/s Ethernet Configuration Interfaces",
                text_16: "Integrated 1GBit/s Ethernet Switch",
                text_17: "1 Internal & 1 External Micro SD Card Slot",
                text_18: "4 Digital Inputs Or Outputs (DIO)",
                text_19: "5 Analog Or Digital Inputs (ADI)",
                text_20: "Integrated WIFI Module (B/N/G)",
                text_21: "Integrated Bluetooth Module",
                text_22: "4.2 BR/EDR BLE Dual Mode Controller",
                text_23: "The device is supported by FL3X Config",
                text_24: "Starting with version Flex Config RBS 5.0",
                text_25: "Supply Voltage: 8-42 VDC",
                text_26: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_27: "Supply Current (Operation Mode): <600mA(@12V)",
                text_28: "Power: Binder Series 702/712-2pol.",
                text_29: "DIO, ADI: Binder Series 702/712-7pol.",
                text_30: "Ethernet, Ports 1-5: Binder Series 702/712-8pol.",
                text_31: "Weight Approx: 975g",
                text_32: "Dimensions L*W*H: 241*124*36mm",
                text_33: "Temperature:",
                text_34: "Operating/Storage: -40°C to +85°C"
            },
            normal: {
                text_1: "2*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "2 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up To 6 FlexRay Controllers",
                text_7: "Up To 8 FlexRay Channels",
                text_8: "Up To 15 CAN-HS Bus Interfaces",
                text_9: "Up To 10 CAN-FD Bus Interfaces",
                text_10: "Wake-up/Sleep Mode",
                text_11: "Wake-up Possible Via FlexRay Or CAN",
                text_12: "Up To 10 100 MBit/s Ethernet Bus Interfaces          ",
                text_13: "Up To 10 100BASE-T1 Bus Interfaces                   ",
                text_14: "Up To 7 1000BASE-T1 Bus Interfaces",
                text_15: "1 GBit/s Ethernet Configuration Interfaces",
                text_16: "Integrated 1GBit/s Ethernet Switch",
                text_17: "1 Internal & 1 External Micro SD Card Slot",
                text_18: "4 Digital Inputs Or Outputs (DIO)",
                text_19: "5 Analog Or Digital Inputs (ADI)",
                text_20: "Integrated WIFI Module (B/N/G)",
                text_21: "Integrated Bluetooth Module",
                text_22: "4.2 BR/EDR BLE Dual Mode Controller",
                text_23: "The device is supported by FL3X Config",
                text_24: "Starting with version Flex Config RBS 5.0",
                text_25: "Supply Voltage: 8-42 VDC",
                text_26: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_27: "Supply Current (Operation Mode): <600mA(@12V)",
                text_28: "Power: Binder Series 702/712-2pol.",
                text_29: "DIO, ADI: Binder Series 702/712-7pol.",
                text_30: "Ethernet, Ports 1-5: Binder Series 702/712-8pol.",
                text_31: "Weight Approx: 975g",
                text_32: "Dimensions L*W*H: 241*124*36mm",
                text_33: "Temperature:",
                text_34: "Operating/Storage: -40°C to +85°C"
            },
            s: {
                text_1: "1*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up To 4 FlexRay Controllers",
                text_7: "Up To 8 FlexRay Channels",
                text_8: "Up To 8 CAN-HS Bus Interfaces",
                text_9: "Up To 6 CAN-FD Bus Interfaces",
                text_10: "Wake-up/Sleep Mode",
                text_11: "Wake-up Possible Via FlexRay Or CAN",
                text_12: "Up to 2 100 MBit/s Ethernet Bus Interfaces",
                text_13: "Up To 3 100BASE-T1 Bus Interfaces",
                text_14: "Up To 2 1000BASE-T1 Bus Interfaces",
                text_15: "1 GBit/s Ethernet Configuration Interface",
                text_16: "Integrated 1 GBit/s Ethernet Switch",
                text_17: "1 Internal & 1 External Micro SD Card Slot",
                text_18: "4 Digital Inputs or Outputs (DIO)",
                text_19: "5 Analog or Digital Inputs (ADI)",
                text_20: "Integrated WiFi Module (B/N/G)",
                text_21: "Integrated Bluetooth Module",
                text_22: "4.2 BR/EDR BLE Dual Mode Controller",
                text_23: "The device is supported by FL3X Config",
                text_24: "Starting with version Flex Config RBS 5.0",
                text_25: "Supply Voltage: 8-42 VDC",
                text_26: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_27: "Supply Current (Operating Mode): <500mA(@12V)",
                text_28: "Power: Binder Series 702/712-2pol.",
                text_29: "ADIO: D-Sub Female– 9pol.",
                text_30: "Ethernet, Port 1-3: RJ45 –8pol./D-Sub male–9pol.",
                text_31: "Weight Approx: 665g",
                text_32: "Dimensions L*W*H: 166*124*36mm",
                text_33: "Temperature:",
                text_34: "Operating/Storage: -40°C to +85°C"
            },
            pxie: {
                text_1: "1*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up To 4 FlexRay Controllers",
                text_7: "Up To 8 FlexRay Channels",
                text_8: "Up To 10 CAN-HS Bus Interfaces",
                text_9: "Up To 10 CAN-FD Bus Interfaces",
                text_10: "Wake-up/Sleep Mode",
                text_11: "Wake-up Possible Via FlexRay Or CAN",
                text_12: "Up To 7 100 MBit/s Ethernet Bus Interfaces",
                text_13: "Up To 7 100BASE-T1 Bus Interfaces",
                text_14: "--",
                text_15: "1 GBit/s Ethernet Configuration Interfaces",
                text_16: "Integrated 1GBit/s Ethernet Switch",
                text_17: "--",
                text_18: "2 Digital Inputs Or Outputs (DIO)",
                text_19: "--",
                text_20: "--",
                text_21: "--",
                text_22: "--",
                text_23: "The device is supported by FL3X Config",
                text_24: "Starting with version Flex Config RBS 5.0",
                text_25: "Supply Voltage: 8-42 VDC",
                text_26: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_27: "Supply Current (Operation Mode): <600mA(@12V)",
                text_28: "Power: Binder Series 702/712-2pol.",
                text_29: "DIO, ADI: Binder Series 702/712-7pol.",
                text_30: "Weight Approx: 180g",
                text_31: "Dimensions (PXIe) L*W*H: 210*130*3U",
                text_32: "Dimensions (PCIe) L*W*H: 172*125*18",
                text_33: "Temperature:",
                text_34: "Operating/Storage: -40°C to +85°C"
            },
        },
        interface: {
            l2: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up to 4 FlexRay Controllers",
                text_7: "Up to 8 FlexRay Bus Channels",
                text_8: "Up to 15 CAN-HS Bus Channels",
                text_9: "Up to 10 CAN-FD Bus Channels",
                text_10: "1 GBit/s Ethernet Configuration Interface",
                text_11: "Integrated WiFi Interface",
                text_12: "5* FL3X Tiny3 Variable Physical Layer Slots",
                text_13: "5* M9 Interfaces",
                text_14: "Supply Voltage: 8-42 VDC",
                text_15: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_16: "Supply Current (Operation Mode): <600mA(@12V)",
                text_17: "Power: Binder Series 702/712-2pol.",
                text_18: "DIO, ADI: Binder Series 702/712-7pol.",
                text_19: "Ethernet, Ports 1-5: Binder 702/712 Series-8pol.",
                text_20: "Weight Approx: 975g",
                text_21: "Dimensions L*W*H: 241*124*36mm",
                text_22: "Temperature:",
                text_23: "Operating/Storage: -40°C to +85°C",
                text_24: "IP-Code: IP20 (IP67 on request)",
                text_25: "Relative Humidity: Operating/Storage: 0% to 90%RH, non-condensing"
            },
            l: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5 Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up to 4 FlexRay Controllers",
                text_7: "Up to 8 FlexRay Bus Channels",
                text_8: "Up to 15 CAN-HS Bus Channels",
                text_9: "Up to 10 CAN-FD Bus Channels",
                text_10: "1 GBit/s Ethernet Configuration Interface",
                text_11: "Integrated WiFi Interface",
                text_12: "5 FL3X Tiny3 Variable Physical Layer Slots",
                text_13: "5 M9 Interfaces",
                text_14: "Supply Voltage: 8-42 VDC",
                text_15: "Supply Current (Sleep Mode): <5mA(@12V)",
                text_16: "Supply Current (Operation Mode): <600mA(@12V)",
                text_17: "Power: Binder Series 702/712-2pin",
                text_18: "DIO, ADI: Binder Series 702/712-7pin",
                text_19: "Ethernet, Ports 1-5: Binder Series 702/712-8pin",
                text_20: "Weight Approx: 955g",
                text_21: "Dimensions L*W*H: 241*124*36mm",
                text_22: "Temperature:",
                text_23: "Operating/Storage: -40°C to +85°C",
                text_24: "IP Code: IP20 (IP67 on request)",
                text_25: "Relative Humidity: Operating/Storage: 0% to 90%RH, non-condensing"
            },
            s: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 Dual Core CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "3 D-Sub Bus Connectors",
                text_5: "2-6 Bus Interfaces per Bus Connector",
                text_6: "Up to 4 FlexRay Controllers",
                text_7: "Up to 2 FlexRay Bus Channels",
                text_8: "Up to 8 CAN-HS Bus Channels",
                text_9: "Up to 6 CAN-FD Bus Channels",
                text_10: "1 GBit/s Ethernet Configuration Interface",
                text_11: "Integrated WiFi Interface",
                text_12: "FL3X Tiny3 Variable Physical Layer Slots",
                text_13: "3 SUB-D Interfaces",
                text_14: "Supply Voltage: 8-42 VDC",
                text_15: "Supply Current (Sleep Mode): <10mA(@12V)",
                text_16: "Supply Current (Operation Mode): <500mA(@12V)",
                text_17: "Power Supply: Binder Series 702/712 - 2 Pins",
                text_18: "ADIO: D-Sub Female - 9 Pins",
                text_19: "Ethernet, Ports 1-3: RJ45 - 8 Pins, D-Sub Male - 9 Pins",
                text_20: "Weight Approx: 665g",
                text_21: "Dimensions L*W*H: 166*124*36mm",
                text_22: "Temperature:",
                text_23: "Operating/Storage: -40°C to +85°C",
                text_24: "IP-Code: IP20",
                text_25: "Relative Humidity: Operating/Storage: 0% to 90%RH, non-condensing"
            }
        },
        media: {
            text_1: "Technical Features",
            text_2: "Electrical Characteristics",
            text_3: "Physical Properties",
            text_4: "Operating Voltage:",
            text_5: "Absolute Maximum (Non-operating):",
            text_6: "Power Current",
            text_7: "Weight Approx",
            text_8: "Dimensions L×W×H",
            text_9: "Operating Temperature:",
            text_10: "Non-operating Temperature:",
            text_11: "Storage Temperature:",
            text_12: "Relative Humidity:",
            text_13: "Environmental Conditions"
        }
    },
    product_detail: {
        normal: {
            description: "Description",
            information: "Product Information",
            advantages: "Advantages",
            tech: "Technical Details",
            download: "Downloads",
            spechsheet: "Spechsheet",
            number: "Article number",
        },
        device: {
            l2: {
                text_1: "The multifunctional bus control unit FlexDevice-L² supports the current and upcoming bus systems and has\n" +
                    "              numerous variable interfaces, thus covering most of the application fields where bus interfaces are\n" +
                    "              required.",
                desc: "FlexDevice-L² is the flagship of the FlexDevice product range, which is used and recognized by many car\n" +
                    "            manufacturers and component suppliers in their automotive electronics development. The FlexDevice family\n" +
                    "            provides manifold solutions for:",
                ad_li_1: "2* ARM Cortex A9 dual core (800 MHz) with 2 GByte DDR RAM",
                ad_li_2: "Up to 30 bus channels (via pluggable transceivers)",
                ad_li_3: "Up to 10 Ethernet channels",
                ad_li_4: "Up to 20 CAN channels",
                ad_li_5: "Up to 10 FlexRay channels (5* channel A and 5* channel B)",
                ad_li_6: "Integrated WiFi and Bluetooth module",
                ad_li_7: "Wake-up/sleep mode support",
                ad_li_8: "Startup < 200ms possible",
                ad_li_9: "Temperature range -40°C to +85°C",
                tech_1_li_1: "2* Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                tech_1_li_2: "2 GByte DDR RAM",
                tech_1_li_3: "5 bus connectors that can be allocated flexibly via pluggable transceivers",
                tech_1_li_4: "2-6 bus interfaces are available on each bus connector",
                tech_1_li_5: "Up to 8 FlexRay controllers (8 Bosch E-Ray Cores)",
                tech_1_li_6: "Up to 10 FlexRay channels (5 channel A and 5 channel B) available on the bus connectors",
                tech_1_li_7: "In any case, 2 FlexRay cores can be connected internally (self-synchronization)",
                tech_1_li_8: "Up to 20 CAN-HS bus interfaces",
                tech_1_li_9: "Up to 20 CAN-FD bus interfaces",
                tech_1_li_10: "Wake-up/sleep support (wake-up possible via FlexRay or CAN)",
                tech_1_li_11: "Up to 10 100 MBit/s Ethernet bus interfaces",
                tech_1_li_12: "Up to 10 100BASE-T1 bus interfaces",
                tech_1_li_13: "1 GBit/s Ethernet configuration interface",
                tech_1_li_14: "Integrated 1 GBit/s Ethernet switch",
                tech_1_li_15: "One internal and one external Micro SD card slot",
                tech_1_li_16: "4 digital inputs or outputs (DIO)",
                tech_1_li_17: "5 analog or digital inputs (ADI)",
                tech_1_li_18: "Integrated WiFi module (b/n/g)",
                tech_1_li_19: "Integrated Bluetooth module (4.2 BR/EDR BLE dual mode controller)"
            },
            l: {
                text_1: "The multifunctional bus control unit FlexDevice-L supports the current and upcoming bus systems and has\n" +
                    "              numerous variable interfaces, thus covering most of the application fields where bus interfaces are\n" +
                    "              required.",
                desc_1: "FlexDevice-L is the flagship of the FlexDevice product range, which is used and recognized by many car manufacturers and component suppliers in their automotive electronics development. The FlexDevice family provides manifold solutions for:",
                desc_2: "Gateways between different automotive bus systems",
                desc_3: "Bus interfaces",
                desc_4: "Remaining bus simulation",
                desc_5: "Signal manipulation",
                desc_6: "Data logging",
                desc_7: "Rapid prototyping",
                ad_li_1: "5 bus connected that can be allocated flexibly via pluggable transceivers (2-6 bus interfaces on each bus connector)",
                ad_li_2: "The following maximum interfaces* can be reached: 4 FlexRay controllers (4 Bosch E-Ray Cores); 8 FlexRay channels; 10 CAN-FD / 15 CAN-FS; 7 Automotive Ethernet 1000BASE-T1; 10 Automotive Ethernet 100BASE-T1; 30 SENT/LIN -(* Depending on the combination of the pluggable transceiver and available FPGA-Images)",
                ad_li_3: "ARM Cortex A9 Dual Core(800 MHz) with 1GByte DDR3 RAM",
                ad_li_4: "Integrated WIFI module",
                ad_li_5: "Wake-up/sleep mode support",
                ad_li_6: "Startup < 200ms possible",
                ad_li_7: "Temperature range -40°C to +85°C",
                tech_1_li_1: "Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                tech_1_li_2: "2 GByte DDR RAM",
                tech_1_li_3: "5 bus connectors that can be allocated flexibly via pluggable transceivers",
                tech_1_li_4: "2-6 bus interfaces are available on each bus connector",
                tech_1_li_5: "Up to 8 FlexRay controllers (8 Bosch E-Ray Cores)",
                tech_1_li_6: "Up to 10 FlexRay channels (5 channel A and 5 channel B) available on the bus connectors",
                tech_1_li_7: "In any case, 2 FlexRay cores can be connected internally (self-synchronization)",
                tech_1_li_8: "Up to 15 CAN-HS bus interfaces",
                tech_1_li_9: "Up to 10 CAN-FD bus interfaces",
                tech_1_li_10: "Wake-up/sleep support (wake-up possible via FlexRay or CAN)",
                tech_1_li_11: "Up to 10 100 MBit/s Ethernet bus interfaces",
                tech_1_li_12: "Up to 10 100BASE-T1 bus interfaces",
                tech_1_li_13: "Up to 7 1000BASE-T1 bus interfaces",
                tech_1_li_14: "1 GBit/s Ethernet configuration interface",
                tech_1_li_15: "Integrated 1 GBit/s Ethernet switch",
                tech_1_li_16: "One internal and one external Micro SD card slot",
                tech_1_li_17: "4 digital inputs or outputs (DIO)",
                tech_1_li_18: "5 analog or digital inputs (ADI)",
                tech_1_li_19: "Integrated WiFi module (b/n/g)",
                tech_1_li_20: "Integrated Bluetooth module (4.2 BR/EDR BLE dual mode controller)"
            },
            s: {
                text_1: "The multifunctional bus control unit FlexDevice-S supports the current and upcoming bus systems and has\n" +
                    "              numerous variable interfaces, thus covering most of the application fields where bus interfaces are\n" +
                    "              required.",
                desc: "FL3X Device-S is the starter of the FL3X Device product family which is used and recognized by many car\n" +
                    "            manufacturers and component suppliers in their automotive electronics development. The FL3X Device family\n" +
                    "            provides manifold solution for:",
                ad_li_1: "1* ARM Cortex A9 dual core (800 MHz) with 1 GByte DDR3 RAM",
                ad_li_2: "Up to 14 bus channels",
                ad_li_3: "2* CAN-HS/FD channels",
                ad_li_4: "1* FlexRay A channel(SelfSync)*",
                ad_li_5: "1* FlexRay B channel(SelfSync)*",
                ad_li_6: "1* 100BASE-T1 channel*",
                ad_li_7: "1* LIN channel",
                ad_li_8: "1 flexible FL3X Tiny3 Slot for additional bus/network transceivers (2x CAN-FD/HS or 4x CAN-FD/HS or 2 x FlexRay (1 A-channel / 1 B-channel) or 2 x 100BASE-T1 or 4 x LIN & 2 x SENT- channels)",
                ad_li_9: "Integrated WIFI module",
                ad_li_10: "D-sub connectors for automotive bus systems",
                ad_li_11: "Wake-up/sleep mode support",
                ad_li_12: "Startup < 200 ms possible",
                ad_li_13: "Micro SD card slot",
                ad_li_14: "IP20",
                ad_li_15: "Temperature range -40°C to +85°C",
                ad_li_16: "* The base unit provides the bus systems CAN-HS and LIN. To use the bus systems FlexRay, CAN-FD or 100BASE-T1, additional licenses are required.",
                "tech_li_1": "Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                "tech_li_2": "1 GByte DDR3 RAM",
                "tech_li_3": "3 D-sub bus connectors with fixed and pluggable transceivers - Up to 4 bus interfaces on each connector",
                "tech_li_4": "Supported automotive bus systems - Up to 4 FlexRay controllers (Bosch E-Ray cores)* - Up to 2 FlexRay channels (2*channel A and 2 channel B) available on the connectors",
                "tech_li_5": "A connector with 2 FlexRay cores provides SelfSync - Up to 4 CAN-FD controllers* - Up to 6 CAN-HS controllers* - Up to 3 100 MBit/s Ethernet controllers*",
                "tech_li_6": "Wake-up/sleep mode support (wake-up possible via FlexRay or CAN)",
                "tech_li_7": "1 GBit/s Ethernet configuration interface via RJ45",
                "tech_li_8": "Integrated 1 GBit/s Ethernet switch",
                "tech_li_9": "Micro SD card slot",
                "tech_li_10": "4 digital inputs/outputs, or analog inputs (ADIO) via D-sub",
                "tech_li_11": "* Several bus combinations are possible, please contact us regarding your desired bus configuration."
            },
            pxie: {
                text_1: "The FL3X Device-PXIe/PCIe combines a high-performance platform for real-time capable\n" +
                    "              remaining bus simulations or gateways and up to 30 channels measuring interface for\n" +
                    "              automotive bus systems in one device.",
                desc: "The FL3X Device-PXIe/PCIe combines a high-performance platform for real-time capable\n" +
                    "            remaining bus simulations or gateways and up to 30 channels* measuring interface for\n" +
                    "            automotive bus systems in one device. This powerful combination provides manifold\n" +
                    "            solutions for:",
                desc_1: "The FL3X Device-PXIe/PCIe supports current and upcoming bus systems and has numerous variable\n" +
                    "            interfaces, thus covering most of the application fields where bus interfaces are required. This\n" +
                    "            device is appreciated by automotive developers since it facilitates the implementation and testing\n" +
                    "            of even very tricky gateway application and prototyping functionalities. Together with the\n" +
                    "            comprehensive FL3X Config software family, the developers are enabled to set up and control their\n" +
                    "            bus systems and ECU environment in a smart way.",
                "ad_li_1": "5* pluggable transceiver modules (2-6 channels per module depending on the bus system)",
                "ad_li_2": "Pluggable transceiver modules available for CAN-HS, CAN-FD, FlexRay, 100BASE-TX, LIN, SENT, 100BASE-T1, 1000BASE-T1",
                "ad_li_3": "Available in PXIe and PCIe form factor",
                "ad_li_4": "Execution of real-time gateways/remaining bus simulations and parallel streaming of all communication channels to the PCIe host system",
                "ad_li_5": "Synchronous timestamp generation for most of the bus interfaces (resolution up to 1 μs)",
                "tech_li_1": "PCIe Gen 1",
                "tech_li_2": "Integrated Altera Cyclone V SOC (ARM Cortex-A9 Dual Core CPU)",
                "tech_li_3": "5 bus connectors that can be allocated flexible via pluggable transceivers - 2-6 bus interfaces are available on each bus connector",
                "tech_li_4": "Up to 4 FlexRay controllers(4 Bosch E-Ray Cores)*",
                "tech_li_5": "Up to 8 FlexRay channels(4 channel A and channel B) available on the bus connectors*",
                "tech_li_6": "In any case, 2 FlexRay cores can be connected internally(SelfSync)",
                "tech_li_7": "Up to 10 CAN-HS bus interfaces*",
                "tech_li_8": "Up to 10 CAN-FD bus interfaces*",
                "tech_li_9": "Up to 7 separate 100BASE-T1 bus interfaces or 15 internally connected (switched) 100BASE-T1 channels*",
                "tech_li_10": "Switchable on-board bus termination",
                "tech_li_11": "Wake-up/sleep mode support (wake-up possible via FlexRay or CAN)",
                "tech_li_12": "Synchronous timestamp generation for all of the bus interfaces (resolution up to 1 us)",
                "tech_li_13": "2 Trigger inputs or -outputs",
                "tech_li_14": "3 different operating modes: - Pure FlexCard mode. Sending/Receiving of bus data at the PCIe host system via the Windows driver - Pure FlexDevice mode. Execution of real time remaining bus simulations and gateways - Mixed mode. Execution of real time remaining bus simulations on the integrated ARM Cortex-A9 CPU and receiving of all bus data at the PCIe host system (PC) Cortex-A9 CPU and receiving of all bus data at the PCIe host system(PC)",
                "tech_li_15": "Gateway and/or remaining bus simulations executed on the integrated ARM Cortex-A9 CPU without influencing the PCIe host system (PC)",
                "tech_li_16": "Streaming of the bus data to the PCIe host system without influencing the gateways/remaining bus simulations executed on the ARM Cortex-A9 CPU",
                "tech_li_17": "* Several bus combinations are possible, please contact us regarding your desired bus configuration.",
            },
        },
        config: {
            text: "FL3X Config is a software to generate a hardware assisted RBS and Gateways. Several network\n" +
                "              description formats are supported. The generated RBS runs independently on a separate hardware.",
            desc: "FL3X Config is a software to generate a hardware assisted RBS and Gateways. Several network\n" +
                "            description formats are supported, such as AUTOSAR, FIBEX, LDF, and CANdb. The generated RBS runs independently on a separate hardware\n" +
                "            RBS runs independently on a separate hardware. FL3X Config allows you to easily log, analyze\n" +
                "            and trace your bus data.",
            "ad_li_1": "Gateway generation for several bus systems, e.g. CAN-HS/FD, FlexRay, 100BASE-T1, 1000BASE-T1, 10BASE-TX, 10BASE-T1S, LIN, SENT",
            "ad_li_2": "Signal manipulation: no coding required",
            "ad_li_3": "Remaining bus simulation (RBS)",
            "ad_li_4": "Easy-to-use GUI: Configuration in two minutes",
            "ad_li_5": "Synchronized FlexRay/FlexRay gateway",
            "ad_li_6": "Support for several devices: all actual FlexDevices and FlexCards",
            "tech_p_1": "Interface to user-defined CRC algorithms, alive/message counter and network management (ANSI-C code)",
            "tech_p_2": "E2E Protection for several OEMs",
            "tech_p_3": "Access RBS runtime parameters via Windows API and Ethernet:",
            "tech_li_1": "Global parameters (read/write)",
            "tech_li_2": "Signals (read/write)",
            "tech_li_3": "PDUs (read/write)",
            "tech_li_4": "Bus controller (switch on/off/read state)",
            "tech_li_5": "Simulated ECUs (switch on/off/read state)",
            "tech_p_4": "FlexConfig RBS includes FlexConfig Control for live manipulations",
            "tech_p_5": "Runs on Windows 10 (64-bit)",
            "tech_p_6": "User Function editor",
            "tech_li_6_1": "Implementation of frame-based and pdu based TX and RX functions per drag and drop and user-defined algorithms.",
            "tech_li_6_2": "Implementation of interrupt-based CycleStartInterrupt functions per drag and drop user-defined algorithms",
            "tech_li_6_3": "User-defined .c/.h files and .a libraries can be included",
            "tech_li_6_4": "Access during runtime to TX or RX main functions via WindowsAPI and Ethernet",
            "tech_li_6_5": "Integration of I/Os per drag and drop",
            "tech_li_6_6": "Signal and frame-based data handling with deposited functionality",
            "tech_li_6_7": "RX User Functions: Payload access to last received frame (e.g. for CRC check)",
            "tech_li_6_8": "Generation of additional TX events for the simulated TX frames (e.g. for transmitting CAN frames which don't have cyclic triggered timings)",
            "tech_li_6_9": "Read/write access to PDU update bits",
            "tech_li_6_10": "Enable/Disable simulated ECUs",
            "tech_li_6_11": "Enable/Disable bus",
            "tech_li_6_12": "Access to user-defines",
            "tech_li_6_13": "Support of user defined sync/startup frames",
            "tech_li_6_14": "Support of DoIP and ISO-TP (on request)",
            "tech_li_6_15": "Support of LIN"
        },
        interface: {
            l2: {
                text: "The FL3X Interface L² is the high-end solution of the FL3X Interface Family with a 1000BASE-T\n" +
                    "              Uplink Port to the Logger or Measurement PC. It can be used with the Standard CMP or PLP Logger\n" +
                    "              Protocol for the usage with a data-logger or alternatively as a full functional Interface with\n" +
                    "              writing and reading ability with our Driver for Linux and Windows.",
                desc_1: "With the FL3X Interface family a highly variable automotive bus interface solution for Data-loggers and PCs,\n" +
                    "            which supports the current and upcoming bus and network systems via pluggable physical layer modules and\n" +
                    "            the usage of a FPGA.",
                desc_2: "The FL3X Interface-L has two FPGAs for the hosting of bus-systems and five FL3X Tiny3 Slots for pluggable\n" +
                    "            transceivers which can provide FlexRay, CAN-HS/FD/SIC and LIN/SENT channels. Because of its free mountable\n" +
                    "            Tiny-slots and the FPGA, it can be extended with any available or upcoming FL3X Tiny3 and communication channel.",
                "ad_li_1": "Five pluggable transceiver modules (2-6 channels per module depending on the bus system)",
                "ad_li_2": "Pluggable transceiver modules available for CAN-FS, CAN-FD, CAN-FD SIC, FlexRay, LIN, SENT*, KLine*, RS232*, SPI*",
                "ad_li_3": "Compact form factor",
                "ad_li_4": "Synchronous timestamp generation for most of the bus interfaces (resolution up to 1μs)",
                "ad_li_5": "Configure bus termination",
                "ad_li_6": "Bosch E-Ray IP core FlexRay controller",
                "ad_li_7": "Bosch D-CAN IP core CAN controller",
                "ad_li_8": "Bosch M-CAN IP core CAN-FD controller",
                "ad_li_9": "Intel Triple-Speed-Ethernet Ethernet controller",
                "ad_li_10": "V2.1 A FlexRay protocol specification",
                "ad_li_11": "V2.0 A/B CAN protocol specification",
                "ad_li_12": "ISO 11898-1:2015 CAN protocol specification",
                "ad_li_13": "Bosch CAN-FD specification 1.0 * API possible but not available at the moment",
                "tech_li_1": "Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                "tech_li_2": "1 GByte DDR3 RAM",
                "tech_li_3": "5 bus connectors that can be allocated flexibly via pluggable transceivers",
                "tech_li_4": "2-6 bus interfaces are available on each bus connector",
                "tech_li_5": "Up to 4 FlexRay controllers (4 Bosch E-Ray Cores)",
                "tech_li_6": "Up to 8 FlexRay channels (4 channel A and 4 channel B) available on the bus connectors",
                "tech_li_7": "Up to 15 CAN-HS bus interfaces",
                "tech_li_8": "Up to 10 CAN-FD bus interfaces",
                "tech_li_9": "1 GBit/s Ethernet configuration interface",
                "tech_li_10": "Integrated WIFI module (b/n/g)"
            },
            l: {
                text: "The FL3X Interface-L is the middle-class solution of the FL3X Interface Family with a 1000BASE-T Uplink\n" +
                    "              Port to the Logger or Measurement PC. It can be used with the Standard CMP or PLP Logger Protocol fot the\n" +
                    "              usage with a data-logger or alternatively as a full functional Interface with writing and reading ability\n" +
                    "              with our Driver for Linux and Windows.",
                "desc_p_1": "With the FL3X Interface family a highly variable automotive bus interface solution for Data-loggers and PCs, which supports the current and upcoming bus and network systems via pluggable physical layer modules and the usage of a FPGA.",
                "desc_p_2": "The FL3X Interface-L has one FPGAs for the hosting of bus-systems and five FL3X Tiny3 Slots for pluggable transceivers which can provide FlexRay, CAN-HS/FD/SIC and LIN/SENT channels. Because of its free mountable Tiny-slots and the FPGA, it can be extended with any available or upcoming FL3X Tiny3 and communication channel.",
                "desc_li_1": "1GBit/s Ethernet and WIFI interface",
                "desc_li_2": "5* FL3X Tiny3 exchangeable Physical Layer slots",
                "desc_li_3": "5* M9 connector",
                "desc_li_4": "8-42V",
                "desc_li_5": "LEDs signal the state of the network",
                "ad_li_1": "Five pluggable transceiver modules (2-6 channels per module depending on the bus system)",
                "ad_li_2": "Pluggable transceiver modules available for CAN-FS, CAN-FD, CAN-FD SIC, FlexRay, LIN, SENT*, K-Line*, RS232*, SPI*",
                "ad_li_3": "Compact form factor",
                "ad_li_4": "Synchronous timestamp generation for most of the bus interfaces (resolution up to 1μs)",
                "ad_li_5": "Configurable bus termination",
                "ad_li_6": "Bosch E-Ray IP core CAN controller",
                "ad_li_7": "Bosch D-CAN IP core CAN controller",
                "ad_li_8": "Bosch M-CAN IP core CAN-FD controller",
                "ad_li_9": "Intel Triple-Speed-Ethernet Ethernet controller",
                "ad_li_10": "V2.1 A FlexRay protocol specification",
                "ad_li_11": "V2.0 A/B CAN protocol specification",
                "ad_li_12": "ISO 11898-1:2015 CAN protocol specification",
                "ad_li_13": "Bosch CAN-FD specification 1.0\n* API possible but not available at the moment",
                "tech_li_1": "Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                "tech_li_2": "1 GByte DDR3 RAM",
                "tech_li_3": "5 bus interfaces that can be allocated flexibly via pluggable transceivers",
                "tech_li_4": "2-6 bus interfaces are available on each bus connector",
                "tech_li_5": "Up to 4 FlexRay controllers (4 Bosch E-Ray Cores)",
                "tech_li_6": "Up to 8 FlexRay controllers (4 channel A and 4 channel B) available on the bus connectors",
                "tech_li_7": "Up to 15 CAN-HS bus interfaces",
                "tech_li_8": "Up to 10 CAN-FD bus interfaces",
                "tech_li_9": "1 GBit/s Ethernet configuration interface",
                "tech_li_10": "Integrated WIFI module (b/n/g)"
            },
            s: {
                text: "The FL3X Interface S is the entry solution of the FL3X Interface Family with a 1000Base-T\n" +
                    "              Uplink Port to the Logger or Measurement PC. It can be used with the Standard CMP or PLP Logger\n" +
                    "              Protocol for the usage with a data-logger or alternatively as a full functional Interface with\n" +
                    "              writing and reading ability with our Driver for Linux and Windows.",
                desc_1: "With the FL3X Interface family a highly variable automotive bus interface solution for Data-loggers and PCs,\n" +
                    "            which supports the current and upcoming bus and network systems via pluggable physical layer modules and\n" +
                    "            the usage of a FPGA.",
                desc_2: "The FL3X Interface-L has two FPGAs for the hosting of bus-systems and five FL3X Tiny3 Slots for pluggable\n" +
                    "            transceivers which can provide FlexRay, CAN-HS/FD/SIC and LIN/SENT channels. Because of its free mountable\n" +
                    "            Tiny-slots and the FPGA, it can be extended with any available or upcoming FL3X Tiny3 and communication\n" +
                    "            channel.",
                "ad_li_1": "One pluggable transceiver modules (2-6 channels per module depending on the bus system)",
                "ad_li_2": "Pluggable transceiver modules available for CAN-FS, CAN-FD, CAN-FD SIC, FlexRay, LIN, SENT*, KLine*, RS232*, SPI*",
                "ad_li_3": "Pluggable transceiver modules 100BASE-T1, 1000BASE-T only for Tap mode",
                "ad_li_4": "Compact form factor",
                "ad_li_5": "Synchronous timestamp generation for most of the bus interfaces (resolution up to 1μs)",
                "ad_li_6": "Configure bus termination",
                "ad_li_7": "Bosch E-Ray IP core FlexRay controller",
                "ad_li_8": "Bosch D-CAN IP core CAN controller",
                "ad_li_9": "Bosch M-CAN IP core CAN-FD controller",
                "ad_li_10": "Intel Triple-Speed-Ethernet Ethernet controller",
                "ad_li_11": "V2.1 A FlexRay protocol specification",
                "ad_li_12": "V2.0 A/B CAN protocol specification",
                "ad_li_13": "ISO 11898-1:2015 CAN protocol specification",
                "ad_li_14": "Bosch CAN-FD specification 1.0\n* API possible but not available at the moment",
                "tech_li_1": "Altera Cyclone V SOC (integrated ARM Cortex A9 Dual Core CPU)",
                "tech_li_2": "1 GByte DDR3 RAM",
                "tech_li_3": "3 D-sub bus connectors with fixed and pluggable transceivers",
                "tech_li_4": "Up to 6 bus/network interfaces on each connector",
                "tech_li_5": "Supported automotive bus/network systems",
                "tech_li_6": "- Up to 4 FlexRay controllers (Bosch E-Ray cores)*",
                "tech_li_7": "- Up to 2 FlexRay channels(2 channel A and 2 channel B) available on the connectors",
                "tech_li_8": "- A connector with 2 FlexRay cores provides SelfSync",
                "tech_li_9": "- Up to 6 CAN-FD controllers*",
                "tech_li_10": "- Up to 8 CAN-HS controllers*",
                "tech_li_11": "- Up to 3 100 MBit/s Ethernet controllers*",
                "tech_li_12": "1 GBit/s Ethernet configuration interface via RJ45",
                "tech_li_13": "* Several bus combinations are possible, please contact us regarding your desired bus configuration."
            },
            pmc: {
                text: "The Fl3X Interface PMC is a bus interface device for automotive bus systems in PMC design. The\n" +
                    "              card's 8 bus interface can be configured for different bus systems via Physical Layer(FL3X Tiny2)\n" +
                    "              slots.",
                desc: "The FL3X Interface-PMC is a bus interface for automotive bus systems in PMC design. The\n" +
                    "            card's 8 bus interfaces can be configured for different bus systems via Physical Layer\n" +
                    "            (FL3X Tiny2) slots.",
                "ad_li_1": "8* exchange bus interfaces",
                "ad_li_2": "Physical Layer available for CAN(-FD,-HS)FlexRay and 100BASE-T1",
                "ad_li_3": "Synchronous timestamp generation for all of the 8 bus interfaces (resolution 1μs)",
                "ad_li_4": "PMC-Adapter available for: PCI, PCIe",
                "ad_li_5": "Driver available for: Windows, Linux, Xenomai",
                "ad_li_6": "Numerous triggering capabilities",
                "ad_li_7": "Switchable on-board bus termination",
                "tech_li_1": "PMC interface (PCI Mezzanine card)",
                "tech_li_2": "Synchronous timestamp generation for all 8 bus interfaces (resolution 1 μs)",
                "tech_li_3": "FL3X Tiny2 exchangeable Physical Layer slots",
                "tech_li_4": "4* Binder 712 8pol connector",
                "tech_li_5": "3.3V DC and 5V DC compatible PCI power supply",
                "tech_li_6": "LEDs signal the state network",
                "tech_li_7": "Trigger- 2x in/out configurable",
                "tech_li_8": "Configurable bus termination",
                "tech_li_9": "Analyzing software FLexAnalyzerV2 included",
                "tech_li_10": "Bosch E-Ray IP Core FLexRay controller",
                "tech_li_11": "Bosch D-CAN IP Core CAN controller",
                "tech_li_12": "Bosch M-CAN IP Core CAN-FD controller",
                "tech_li_13": "More than IP 10/100/1000 MBit/s Ethernet controller",
                "tech_li_14": "V2.1 A FlexRay protocol specification",
                "tech_li_15": "V2.0 A/B CAN protocol specification",
                "tech_li_16": "ISO 11898-1:2015 CAN protocol specification",
                "tech_li_17": "Bosch CAN-FD specification 1.0"
            },
            pxie: {
                text: "The FL3X Interface PCIe/PXIe is the middle class solution of the FL3X Interface Family in the\n" +
                    "              PCIe form factor for usage in a Logger or Measurement PC. It can be used as a full functional\n" +
                    "              Interface with writing and reading ability with our Driver for Linux, Windows or Xenomai.",
                desc_1: "With the FL3X Interface family a highly variable automotive bus interface solution for Data-loggers\n" +
                    "            and PCs, which supports the current and upcoming bus and network systems via pluggable physical layer\n" +
                    "            modules and the usage of a FPGA.",
                desc_2: "The FL3X Interface PCIe/PXIe has one FPGA for the hosting of bus-systems and five FL3X Tiny3 slots\n" +
                    "            for pluggable transceivers which can provide FlexRay, CAN-HS/FD/SIC, LIN/SENT, 10BASE-T1S, 100BASE-TX\n" +
                    "            and 100/1000BASE-T1 channels, Because of its free mountable slots and the FPGA, it can be extended with\n" +
                    "            any available or upcoming FL3X Tiny3 and communication channel.",
                "ad_li_1": "Five pluggable transceiver modules (2-6 channels per module depending on the bus system)",
                "ad_li_2": "Pluggable transceiver modules available for CAN-HS, CAN-FD, CAN-FD SIC, FlexRay, 100BASE-TX, 10BASE-T1S, 100BASE-T1, 1000BASE-T1, LIN, SENT* , K-Line* , RS232* , SPI*",
                "ad_li_3": "Compact form factor",
                "ad_li_4": "Synchronous timestamp generation for most of the bus interfaces (resolution up to 1μs and 1ns)",
                "ad_li_5": "Configurable bus termination",
                "ad_li_6": "Bosch E-Ray IP core FlexRay controller",
                "ad_li_7": "Bosch D-CAN IP core CAN controller",
                "ad_li_8": "Bosch M-CAN IP core CAN-FD controller",
                "ad_li_9": "Intel Triple-Speed-Ethernet Ethernet controller",
                "ad_li_10": "V2.1 A FlexRay protocol specification",
                "ad_li_11": "V2.0 A/B CAN protocol specification",
                "ad_li_12": "ISO 11898-1:2015 CAN protocol specification",
                "ad_li_13": "Bosch CAN-FD specification 1.0",
                "tech_li_1": "Integrated Altera Cyclone V SOC (ARM Cortex-A9 Dual Core CPU)",
                "tech_li_2": "5 bus connectors that can be allocated flexible via pluggable transceivers - 2-6 bus interfaces are available on each bus connector",
                "tech_li_3": "Up to 3 FlexRay controllers (4 Bosch E-Ray Cores)*",
                "tech_li_4": "Up to 6 FlexRay channels (3 channel A and 3 channel B) available on the bus connectors*",
                "tech_li_5": "In any case, 2 FlexRay cores can be connected internally (SelfSync)",
                "tech_li_6": "Up to 6 CAN-HS bus interfaces*",
                "tech_li_7": "Up to 6 CAN-FD bus interfaces*",
                "tech_li_8": "1 100BASE-T1 bus interfaces - Several bus combinations are possible, please contact us regarding your desired bus configuration."
            },
        },
        media: {
            base10: {
                text: "The FL3X Media 10BASE-T1 is a converter for 10BASE-T1 to 10BASE-TX and vice versa. With\n" +
                    "              the FL3X Media 10BASE-T1, it is possible to connect an automotive Ethernet network to a standard\n" +
                    "              PC for testing or diagnostic purposes.",
                desc: "The FL3X Media 10BASE-T1 is a converter for 10BASE-T1 to 10BASE-TX and vice versa.\n" +
                    "            With the FL3X Media 10BASE-T1, it is possible to connect an automotive Ethernet network\n" +
                    "            to a standard PC for testing or diagnostic purposes. It supports up to 10 MBits in full\n" +
                    "            duplex mode on both sides.",
                "ad_li_1": "Master and slave mode selectable via DIP-switch",
                "ad_li_2": "Broadcom BCM898 10 transceiver is used for the 100BASE-T1 conversion",
                "ad_li_3": "Supply voltage: 8-48V DC",
                "ad_li_4": "Status LEDs",
                "ad_li_5": "RJ45 connector for 10BASE-TX",
                "ad_li_6": "D-Sub 9 pin male connector for 10BASE-T1",
                "ad_li_7": "IP20",
                "ad_li_8": "Temperature range - 40°C to + 85°C",
                "tech_li_1": "Electrical Characteristics",
                "tech_li_2": "- Supply voltage Operating: +8.0V to +48.0V",
                "tech_li_3": "- Absolute maximum (non-operating): -60.0V to +60.0V",
                "tech_li_4": "- Supply current (operating): typ: 80mA",
                "tech_li_5": "Physical Characteristics",
                "tech_li_6": "- Connectors: RJ45, D-Sub 9",
                "tech_li_7": "- Weight approx.: 145g",
                "tech_li_8": "- Dimensions approx. L×W×H: 92×65×28mm³",
                "tech_li_9": "Environmental Conditions",
                "tech_li_10": "- Temperature Operating: -40°C to +85°C",
                "tech_li_11": "- Temperature Storage: -40°C to +85°C",
                "tech_li_12": "- Relative Humidity: 0% - 90% r.H., non-condensing"
            },
            base100: {
                text: "The FL3X Media 100BASE-T1 is a converter for 100BASE-T1 to 100BASE-TX and vice versa. With\n" +
                    "              the FL3X Media 100BASE-T1, it is possible to connect an automotive Ethernet network to a standard\n" +
                    "              PC for testing or diagnostic purposes.",
                desc: "The FL3X Media 100BASE-T1 is a converter for 100BASE-T1 to 100BASE-TX and vice versa.\n" +
                    "            With the FL3X Media 100BASE-T1, it is possible to connect an automotive Ethernet network\n" +
                    "            to a standard PC for testing or diagnostic purposes. It supports up to 100 MBits in full\n" +
                    "            duplex mode on both sides.",
                "ad_li_1": "Master and slave mode selectable via DIP-switch",
                "ad_li_2": "Broadcom BCM898 10 transceiver is used for the 100BASE-T1 conversion",
                "ad_li_3": "Supply voltage: 8-48V DC",
                "ad_li_4": "Status LEDs",
                "ad_li_5": "RJ45 connector for 100BASE-TX",
                "ad_li_6": "D-Sub 9 pin male connector for 100BASE-T1",
                "ad_li_7": "IP20",
                "ad_li_8": "Temperature range - 40°C to + 85°C",
                "tech_li_1": "Electrical Characteristics",
                "tech_li_2": "- Supply voltage Operating: +8.0V to +48.0V",
                "tech_li_3": "- Absolute maximum (non-operating): -60.0V to +60.0V",
                "tech_li_4": "- Supply current (operating): typ: 80mA",
                "tech_li_5": "Physical Characteristics",
                "tech_li_6": "- Connectors: RJ45, D-Sub 9",
                "tech_li_7": "- Weight approx.: 145g",
                "tech_li_8": "- Dimensions approx. L×W×H: 92×65×28mm³",
                "tech_li_9": "Environmental Conditions",
                "tech_li_10": "- Temperature Operating: -40°C to +85°C",
                "tech_li_11": "- Temperature Storage: -40°C to +85°C",
                "tech_li_12": "- Relative Humidity: 0% - 90% r.H., non-condensing"
            },
            base1000: {
                text: "The FL3X Media 1000BASE-T1 is a converter for 1000BASE-T1 to 1000BASE-T and vice versa. With\n" +
                    "              the FL3X Media 1000BASE-T1, it is possible to connect an automotive Ethernet network to a standard\n" +
                    "              PC for testing or diagnostic purposes",
                desc: "The FL3X Media 1000BASE-T1 is a converter for 100/1000BASE-T1 to 100/1000BASE-T and vice versa.\n" +
                    "            With the FL3X Media 1000BASE-T1, it is possible to connect an automotive Ethernet network to a\n" +
                    "            standard PC for testing or diagnostic purposes. It supports up to 1000 MBit/s in full duplex mode\n" +
                    "            on both sides.",
                "tech_li_1": "Master and slave mode selectable via DIP-switch",
                "tech_li_2": "100 MBit/s and 1000 MBit/s mode selectable via DIP-switch",
                "tech_li_3": "Automatic selection of A0 and A2 mode",
                "tech_li_4": "Marvell 88Q21 12-A2-NYD2A000 transceiver is used for the 1000BASE-T1 conversion",
                "tech_li_5": "Supply voltage: 8-48V DC",
                "tech_li_6": "Status LEDs",
                "tech_li_7": "RJ45 connector for 1000BASE-T",
                "tech_li_8": "D-Sub 9 pin male connector for 1000BASE-T1",
                "tech_li_9": "IP20",
                "tech_li_10": "Temperature range: -40°C to +70°C",
                "tech_ad_li_1": "Electrical Characteristics",
                "tech_ad_li_1_sub": [
                    "- Supply voltage Operating: +8.0V to +48.0V",
                    "- Absolute maximum (non-operating): -60.0V to +60.0V",
                    "- Supply current - operating (typical): 140mA"
                ],
                "tech_ad_li_2": "Physical Characteristics",
                "tech_ad_li_2_sub": [
                    "- Connectors: RJ45, D-Sub 9",
                    "- Weight (approx.): 145g",
                    "- Dimensions (approx.): L×W×H 92×65×28mm^3"
                ],
                "tech_ad_li_3": "Environmental Conditions",
                "tech_ad_li_3_sub": [
                    "- Temperature Operating: -40°C to +70°C",
                    "- Temperature Storage: -40°C to +70°C",
                    "- Relative Humidity: 0% - 90% r.H., non-condensing"
                ]
            },
        },
        switch: {
            base10: {
                text: "The Switch provides 7 × 100/1000BASE-T1 ports and one 2.5G/5G/10G BASE-T1 port. Additionally\n" +
                    "              one SFP+ port up to 10G. The switch is based on the 1000BASE-T1 Marvell Switch Chips 88Q6113,\n" +
                    "              it can easily configured by using proprietary software provided by Marvell.",
                desc_1: "This switch includes a high-performance ARM® Cortex M7 CPU with dedicated on-chip memory\n" +
                    "            to support AVB protocols such as Precision Time Protocol (PTP) and security firewall to protect\n" +
                    "            from external malicious attacks. The switch employs advanced routing engine to support gigabit\n" +
                    "            routing of the incoming packets without the CPU intervention.",
                desc_2: "It offers highest level of security with deep packet inspection (DPI) techniques, Denial of\n" +
                    "            Service (Dos) engine and Trusted Boot functionality to secure the vehicle networks.",
                "ad_li_1": "7 * 100/1000BASE-T1 with H-MTD connector",
                "ad_li_2": "1 * MultiGig Port up to 10G BASE-T1 with H-MTD connector",
                "ad_li_3": "1 * SFP+ port",
                "ad_li_4": "Marvell 88Q6113 Switch Chip",
                "ad_li_5": "Fully compliant with IEEE802.3",
                "ad_li_6": "802.1 AS PTP support for master/slave and transparent clock",
                "ad_li_7": "Supply voltage: 8 to 48 V DC",
                "ad_li_8": "Temperature range: -40°C to +85°C",
                "ad_li_9": "Integrated RTC (Real Time Clock)",
                "tech_li_1": "Operating voltage: +8.0V to +48.0V",
                "tech_li_2": "Absolute maximum voltage (non-operating): -60.0V to +60.0V",
                "tech_li_3": "Supply current - operating: Typ: 670mA",
                "tech_li_4": "Connectors: 1 * Binder 2pol. / 1 * SFP+ / 8 * H-MTD",
                "tech_li_5": "Weight: approx. 625g",
                "tech_li_6": "Dimensions: approx. L * W * H 166 * 124 * 36 mm",
                "tech_li_7": "Operating temperature: -40°C to +85°C",
                "tech_li_8": "Non-operating temperature: -40°C to +85°C",
                "tech_li_9": "Storage temperature: -40°C to +85°C",
                "tech_li_10": "IP-Code: IP20",
                "tech_li_11": "Relative Humidity: 0% - 90% r.H., non-condensing"
            },
            base:{
                text: "FL3X Switch 1000BASE-T1 is one of the first 1000BASE-T1 Automotive Ethernet Switches on the\n" +
                    "              market and can also score with special filter mechanisms. For each port of the switch, criteria\n" +
                    "              can be defined that allow blocking of certain traffic on that port.",
                desc: "FL3X Switch 1000BASE-T1 is one of the first 1000BASE-T1 switches on the market and can\n" +
                    "            also score with special filter mechanisms. For each port of the switch, criteria can be defined\n" +
                    "            that allow blocking of certain traffic on that port. The switch thus acts as a data filter if\n" +
                    "            required and can arbitrarily reduce the data traffic for downstream hardware. All functionality\n" +
                    "            is implemented in hardware, so the switch has excellent real-time behavior.",
                "tech_li_1": "7 * 100/1000BASE-T1 ports",
                "tech_li_2": "2 * SFP+ ports",
                "tech_li_3": "Marvell 88Q6113 Switch Chip",
                "tech_li_4": "Fully compliant with IEEE802.3",
                "tech_li_5": "Harting T1 connectors",
                "tech_li_6": "Temperature range: -40°C to +85°C",
                "tech_li_7": "802.1 AS PTP Support for master/slave and transparent clock",
                "tech_li_8": "Integrated ARM Cortex M7 CPU",
                "tech_1": "Operation: +8.0V to +48.0V",
                "tech_2": "Absolute maximum (non-operating): -60.0V to +60.0V",
                "tech_3": "Supply current - operating: Typ: 800mA",
                "tech_4": "Connections: 1 * Binder 2pol. / 2 * SFP+ / 7 * Harting T1",
                "tech_5": "Weight approx.: 625g",
                "tech_6": "Dimensions approx. L * W * H",
                "tech_7": "Temperature Operating: -40°C to +85°C",
                "tech_8": "Temperature Non-operating: -40°C to +85°C",
                "tech_9": "Temperature Storage: -40°C to +85°C",
                "tech_10": "IP-Code: IP20",
                "tech_11": "Relative Humidity: 0% - 90% r. H., non-condensing"
            }
        },
        system: {
            m: {
                text: "The FL3X System-M is the mid-range variant of the FL3X System family. Due to the flexible\n" +
                    "              expandability and the modular platform, the FL3X System-M can be perfectly adapted to\n" +
                    "              your needs.",
                desc: "The FL3X System-M is the mid-range variant of the FL3X System family and is characterized by\n" +
                    "            high performance optimized for the multi-gigabit range and high flexibility with regard to the\n" +
                    "            equipment with bus systems. Due to the flexible expandability and the modular platform, the\n" +
                    "            FL3X System-M can be perfectly adapted to your needs. The freely configurable assembly enables\n" +
                    "            a large selection of channel configurations with e.g. CAN, FlexRay and Automotive Ethernet.\n" +
                    "            The external interfaces such as 1000BASE-T, USB and WiFi allow you to transfer data and connect\n" +
                    "            accessories in a variety of ways. Even large data storage devices can be connected without any\n" +
                    "            problems in order to log high data streams. Thus, gateways with high data through puts,\n" +
                    "            remaining bus simulations with a large number of ECUs or the logging of several data streams\n" +
                    "            can be implemented with the FL3X System-M without any problems.",
                "tech_li_1": "Intel® Core™ i3 CPU, 8 GB RAM and 128 GB NVMe SSD",
                "tech_li_2": "Maximum performance and variability",
                "tech_li_3": "CAN-HS/FD, FlexRay, 100BASE-T1, 1000BASE-T1, 2.5/5/10GBase-T1, 100BASE-TX, 1000BASE-T, LIN and SENT supported",
                "tech_li_4": "Up to 2 FL3X Device-PCIe or 2 FL3X Interface-PMC with up to 10 FL3X Tiny Slots (also possible in combination)",
                "tech_li_5": "Up to 5 SFP(+) ports",
                "tech_li_6": "1000BASE-T connector for configuration and measuring",
                "tech_li_7": "Fully supported by FL3X Config",
                "tech_li_8": "PTP/IEEE 1558v2 support for TimeSync",
                "tech_li_9": "USB ports for mass storage, cameras or other equipment*",
                "tech_li_10": "Integrated WIFI module",
                "tech_li_11": "Temperature range: -40°C to +70°C",
                "tech_li_12": "Remote power on/off",
                "tech_li_13": "* STAR certified accessories only",
                "tech_1": "Intel® Core™ i3 CPU",
                "tech_2": "8 GByte RAM",
                "tech_3": "128 GByte NVMe SSD",
                "tech_4": "PTP/IEEE 1588V2 support for TimeSync on bus and config port",
                "tech_5": "ASAM CMP 1.0 and PLP for Measurement*1",
                "tech_6": "1GBit/s Ethernet configuration and measurement interface via RJ45",
                "tech_7": "USB 3.2 ports for mass storage, cameras and other components*2",
                "tech_8": "WIFI connector",
                "tech_9": "Wide operating temperature range from -40°C to +70°C",
                "tech_10": "*1 coming soon",
                "tech_11": "*2 STAR certified accessories only"
            },
            xs:{
                text: "The FL3X System-XS is the entry-level version of the FL3X System family and is characterized by\n" +
                    "              an extremely good price/performance ratio and very compact dimensions. In the basic configuration,\n" +
                    "              the FL3X System-XS is equipped with a CAN-HS or CAN-FD channel as well as LIN and SENT channel.",
                desc: "The FL3X System-XS is the entry-level version of the FL3X System family and is characterized by\n" +
                    "            an extremely good price/performance ratio and very compact dimensions. In the basic configuration,\n" +
                    "            the FL3X System-XS is equipped with a CAN-HS or CAN-FD channel as well as a LIN and SENT channel. It\n" +
                    "            is therefore very well suited as a bus interface, mini logger and for remaining bus simulations. With\n" +
                    "            the XS² variant, you have two CAN, two LIN and two SENT channels available, which means that it is also\n" +
                    "            very well suited as a gateway or bus manipulator for these bus systems. The Automotive Ethernet variant\n" +
                    "            XS³ also offers you a 100BASE-T1 channel.",
                "tech_li_1": "ARM Cortex-A72 64 bit Quad Core CPU with 1GHz",
                "tech_li_2": "1GByte RAM",
                "tech_li_3": "8GByte eMMC",
                "tech_li_4": "CAN-HS, CAN-FD, LIN, SENT, and 100BASE-T1 supported",
                "tech_li_5": "Fully supported by FL3X Config",
                "tech_li_6": "25-pin D-sub connector for automotive bus systems",
                "tech_li_7": "PTP/IEEE1588v2 support for TimeSync",
                "tech_li_8": "Wake-up/sleep mode support",
                "tech_li_9": "1000BASE-T connector for configuration and measuring",
                "tech_li_10": "USB port for mass storage, cameras, or other equipment*",
                "tech_li_11": "Integrated WIFI module and optional LTE module",
                "tech_li_12": "Temperature range -20°C to +60°C",
                "tech_li_13": "* STAR certified accessories only",
                "tech_1": "ARM Cortex-A72 64 bit Quad Core CPU with 1GHz",
                "tech_2": "1 GByte DDR4 RAM",
                "tech_3": "8 GByte eMMC flash",
                "tech_4": "PTP/IEEE1588v2 support for TimeSync on bus and config port",
                "tech_5": "ASAM CMP 1.0 and PLP for measurement*",
                "tech_6": "Wakeup/sleep mode support for CAN-HS and CAN-FD",
                "tech_7": "1 GBit/s Ethernet configuration and measurement interface via RJ45",
                "tech_8": "25-pin D-sub connector for power, bus, and DO/ADIO",
                "tech_9": "Integrated WIFI module with 2.4GHz, 5.0GHz IEEE 802.11 b/n/g/ac support",
                "tech_10": "Optional LTE Cat 4 module*, supports LTE-TDD, LTE-FDD, HSPA+, GSM, GPRS, and EDGe with data-rates up to 150 MBit/s download and 50 MBit/s upload rate",
                "tech_11": "* coming soon"
            },
        }
    }
}