import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('../views-en/home.vue')
    },

    // product router
    {
        // 网址对应路径
        path: '/product',
        // 页面组件对应地址 (相对位置)
        component: () => import('../views-en/product.vue')
        // component:() => import('@/views/product.vue') 页面组件对应地址 (绝对位置)
    },

    {
        path: '/application',
        component: () => import('../views-en/application.vue')
    },
    {
        path: '/support',
        component: () => import('../views-en/support.vue')
    },
    {
        path: '/contact',
        component: () => import('../views-en/contact.vue')
    },

    {
        path: '/application/restbus-simulation',
        component: () => import('@/views-en/application/restbus-simulation.vue')
    },
    {
        path: '/application/gateway',
        component: () => import('@/views-en/application/gateway.vue')
    },
    {
        path: '/application/signal-manipulation',
        component: () => import('@/views-en/application/signal-manipulation.vue')
    },
    {
        path: "/application/logging",
        component: () => import('@/views-en/application/logging.vue')
    },
    {
        path: "/application/user-function",
        component: () => import('@/views-en/application/user-function.vue')
    },

    // support routers
    {
        path: '/support/academy-training',
        component: () => import('../views-en/support/academy-training.vue')
    },
    {
        path: '/support/driver-download',
        component: () => import('../views-en/support/driver-download.vue')
    },
    {
        path: '/support/user-manuals',
        component: () => import('../views-en/support/user-manuals.vue')
    },
    {
        path: '/support/desc/fl3x-config',
        component: () => import('../views-en/support/desc/fl3x-config.vue')
    },
    {
        path: '/support/desc/some-ip-and-ethernet',
        component: () => import('../views-en/support/desc/some-ip-and-ethernet.vue')
    },
    {
        path: '/support/desc/can',
        component: () => import('../views-en/support/desc/can.vue')
    },
    {
        path: '/support/desc/lin-and-sent',
        component: () => import('../views-en/support/desc/lin-and-sent.vue')
    },
    {
        path: '/support/desc/flexRay',
        component: () => import('../views-en/support/desc/flexRay.vue')
    },

    {
        path: '/compare/device',
        component: () => import('../views-en/compare/device.vue')
    },
    {
        path: '/compare/interface',
        component: () => import('../views-en/compare/interface.vue')
    },
    {
        path: '/compare/media-switch',
        component: () => import('../views-en/compare/media-switch.vue')
    },

    // products routers
    {
        path: '/product/device-l-pro',
        component: () => import('../views-en/product/device-l-pro.vue')
    },
    {
        path: '/product/device-l',
        component: () => import('../views-en/product/device-l.vue')
    },
    {
        path: '/product/device-s',
        component: () => import('../views-en/product/device-s.vue')
    },
    {
        path: '/product/device-pxie-pcie',
        component: () => import('../views-en/product/device-pxie-pcie.vue')
    },
    {
        path: '/product/interface-l-pro',
        component: () => import('../views-en/product/interface-l-pro.vue')
    },
    {
        path: '/product/interface-l',
        component: () => import('../views-en/product/interface-l.vue')
    },
    {
        path: '/product/interface-s',
        component: () => import('../views-en/product/interface-s.vue')
    },
    {
        path: '/product/interface-pxie-pcie',
        component: () => import('../views-en/product/interface-pxie-pcie.vue')
    },
    {
        path: '/product/interface-pmc',
        component: () => import('../views-en/product/interface-pmc.vue')
    },
    {
        path: '/product/system-m',
        component: () => import('../views-en/product/system-m.vue')
    },
    {
        path: '/product/system-xs',
        component: () => import('../views-en/product/system-xs.vue')
    },
    {
        path: '/product/system-xs-pro',
        component: () => import('../views-en/product/system-xs-pro.vue')
    },
    // {
    //     path: '/product/media-10base',
    //     component: () => import('../views-en/product/media-10base.vue')
    // },
    {
        path: '/product/media-100base',
        component: () => import('../views-en/product/media-100base.vue')
    },
    {
        path: '/product/media-1000base',
        component: () => import('../views-en/product/media-1000base.vue')
    },
    {
        path: '/product/switch-1000-10g-base',
        component: () => import('../views-en/product/switch-1000-10g-base.vue')
    },
    {
        path: '/product/switch-1000base',
        component: () => import('../views-en/product/switch-1000base.vue')
    },
    {
        path: '/product/flex-config',
        component: () => import('../views-en/product/flex-config.vue')
    },


]


const router = new Router({
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 返回滚动位置：始终滚动到顶部
        return { x: 0, y: 0 }
    }
})

export default router