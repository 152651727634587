<template>
  <div id="app">
    <navbar></navbar>
    <router-view></router-view>
    <footer-component></footer-component>
  </div>
</template>

<script>
import FooterComponent from './components/Footer.vue';
import Navbar from './components/Navbar.vue';



export default {
  name: 'App',
  components: {
    FooterComponent,
    Navbar
  },
  data() {
    return {
      currentNavbarKey: 0,
      currentFooterKey: 0,
      language: localStorage.getItem("lang") || "EN"
    };
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style>
</style>