<template>
  <div class="navbar-container">
    <!-- navbar -->
    <nav class="navbar">
      <img :src="require('@/assets/images/logo/StarE22-048_Flex_Logo_RGB_1c_black_Logo.png')" alt="Logo" class="logo">
      <ul class="nav-links">
<!--        <li><router-link to="/">{{ $t('h.navbar.home') }}</router-link></li>-->
<!--        <li><router-link to="/product">{{ $t('h.navbar.product') }}</router-link></li>-->
<!--        <li><router-link to="/application">{{ $t('h.navbar.application') }}</router-link></li>-->
<!--        <li><router-link to="/support">{{ $t('h.navbar.support') }}</router-link></li>-->
<!--        <li><router-link to="/contact">{{ $t('h.navbar.contact') }}</router-link></li>-->
        <li v-for="link in links" :key="link.text">
          <router-link v-if="link.show" :to="link.to">{{ link.text }}</router-link>
        </li>
      </ul>
      <div class="language-container">
        <router-link to="/support/driver-download">
          <button class="download-btn">{{ $t('h.navbar.driver_download') }}</button>
        </router-link>
        <img :src="require('@/assets/images/icons/en-zh.png')" alt="Change Language" @click="changeLangEvent()"
             class="language-icon">
      </div>
    </nav>

    <!-- navbar mobile -->
    <div class="navbar-mobile">
      <div class="navbar-left">
        <img :src="require('@/assets/images/logo/StarE22-048_Flex_Logo_RGB_1c_black_Logo.png')" alt="Logo" class="logo">
      </div>
      <div class="navbar-right">
        <el-button class="close-button" @click="openSidebar">
          <img :src="require('@/assets/images/icons/menu.png')" alt="menu-icon" class="menu-icon">
        </el-button>
      </div>
    </div>

    <!-- Aside bar -->
    <el-aside width="auto" class="aside-bar" v-show="isSidebarVisible">
      <el-menu default-active="1" class="el-menu-container">
        <div class="sidebar-header">
          <img :src="require('@/assets/images/icons/en-zh.png')" alt="Change Language" @click="changeLangEvent()"
               class="language-icon-sidebar">
          <el-button class="close-button" icon="el-icon-close" circle @click="closeSidebar"></el-button>
        </div>
        <el-menu-item index="/">
          <router-link to="/" class="side-link">{{ $t('h.navbar.home') }}</router-link>
        </el-menu-item>
        <el-menu-item index="/product">
          <router-link to="/product" class="side-link">{{ $t('h.navbar.product') }}</router-link>
        </el-menu-item>
        <el-menu-item index="/application">
          <router-link to="/application" class="side-link">{{ $t('h.navbar.application') }}</router-link>
        </el-menu-item>
        <el-menu-item index="/support">
          <router-link to="/support" class="side-link">{{ $t('h.navbar.support') }}</router-link>
        </el-menu-item>
        <el-menu-item index="/contact">
          <router-link to="/contact" class="side-link">{{ $t('h.navbar.contact') }}</router-link>
        </el-menu-item>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  data() {
    return {
      isSidebarVisible: false,
      language: localStorage.getItem("lang") || "EN",
      links: [
        { to: "/", text: this.$t('h.navbar.home'), show: true },
        { to: "/product", text: this.$t('h.navbar.product'), show: true },
        { to: "/application", text: this.$t('h.navbar.application'), show: true },
        { to: "/support", text: this.$t('h.navbar.support'), show: true },
        { to: "/contact", text: this.$t('h.navbar.contact'), show: true }
      ]
    }
  },
  computed: {
  },
  methods: {
    closeSidebar() {
      this.isSidebarVisible = false;
    },
    openSidebar() {
      this.isSidebarVisible = true;
    },
    changeLangEvent() {
      console.log(this.$i18n);
      if (this.language === "EN") {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = localStorage.getItem("locale");
        this.$message({
          message: "切换为中文！",
          type: "success"
        });
        localStorage.setItem("lang", "ZH");
        this.language = "ZH";
        location.reload(); //重新加载
      } else if (this.language === "ZH") {
        localStorage.setItem("locale", "en");
        this.$i18n.locale = localStorage.getItem("locale");
        this.$message({
          message: "Switch to English!",
          type: "success"
        });
        localStorage.setItem("lang", "EN");
        this.language = "EN";
        location.reload();
      }
    }
  }
};
</script>

<style scoped>
/* element css */
@import '~element-ui/lib/theme-chalk/index.css';


@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(~@/assets/fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* navbar style */
.navbar-container {
  font-family: Montserrat, SF Pro SC, SF Pro Display, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, sans-serif;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  background: white;
  color: white;
  font-weight: bold;
}

.logo {
  height: 40px;
  align-items: center;
  opacity: 0.8;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 15px;
  letter-spacing: 2px;
  transition: color 0.3s ease;
  opacity: 0.7;
}

.nav-links a:hover {
  color: #aaa;
}

.language-container {
  display: flex;
  justify-content: flex-end;
}

.language-icon {
  padding-left: 15px;
  max-width: 35px;
}

.language-icon-sidebar {
  max-width: 30px;
}

.download-btn {
  padding: 12px 3rem;
  cursor: pointer;
  background: black;
  border: none;
  color: white;
  letter-spacing: 5px;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #4c4d50;
}

.navbar-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navbar-left {
  flex-basis: 90%;
}

.navbar-right {
  flex-basis: 10%;
}

.menu-icon {
  height: 25px;
  width: auto;
}

.aside-bar {
  margin: 0;
  padding: 0;
  height: 400px;
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}

.side-link {
  font-weight: bold;
  text-decoration: none;
  color: black;
  font-size: 12px;
  letter-spacing: 2px;
  transition: color 0.3s ease;
  opacity: 0.7;
}

.side-link:hover {
  color: #aaaaaa;
}

.close-button {
  border: none;
}

.el-menu-container:not(.el-menu--collapse) {
  text-align: left;
  border: 0;
}


@media (max-width: 767px) {
  .navbar {
    display: none;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .navbar-mobile {
    display: none;
  }

  .aside-bar {
    display: none;
  }
}
</style>
