export const h = {
    navbar: {
        home: "主页",
        product: "产品目录",
        application: "应用场景",
        support: "技术支持",
        contact: "联系我们",
        driver_download: "下载中心"
    },
    footer: {
        address: "地址",
        address_t1: "施达汽车工程咨询(上海)有限公司",
        address_t2: "静安区威海路567号晶采世纪大厦6H,6I",
        address_t3: "200041 上海",
        address_t4: "",
        address_t5: "",
        contact: "联系方式",
        company: "公司信息",
        company_t1: "了解我们",
        company_t2: "联系我们",
        company_t3: "微信",
        legal: "法律法规",
        legal_t1: "网站信息",
        legal_t2: "数据保护",
        legal_t3: "通用条款"
    },
    home: {
        title: "汽车测试及质量监控\n博览会",
        subtitle: "施达工程咨询(上海)有限公司",
        learn_more: "了解更多",
        text_title: "汽车电子设备及解决方案",
        text_subtitle: "与我们一同探索车辆电子和网络技术的新风向",
        about_1: "简介",
        about_2: "我们的产品有哪些？",
        about_3: "STAR Electronics 专注于汽车领域, 向客户提供量身定制的软硬件解决方案及一系列标准与特殊工具，" +
            "广泛应用于仿真技术，测量控制技术及能源管理技术等领域。",
        contact_1: "团队",
        contact_2: "我们提供哪些服务？",
        contact_3: "STAR中国向您展示STAR Electronics的全系列产品。我们的专业团队将依据您的具体需求," +
            "为您量身定制最优解决方案,旨在提供最高品质的产品和卓越服务体验。",
    },
    product: {
        detail: "产品详情",
        compare: "设备比较",
        subtitle: "FlexDevice 产品系列\\n" +
            "现如今车辆电子和网络技术正变得日益重要：越来越多的车辆组件实现了互联。STAR COOPERATION® 使车辆电子系统的开发变得更为容易。为复杂网络技术提供定制化的软件和硬件解决方案，使您享受前所未有的便捷。",
        title: "车辆电子和网络技术的高效解决方案",
        device: "旗舰级多功能总线控制单元",
        interface: "多功能总线通道工具",
        switch: "汽车以太网交换机",
        media: "汽车及标准以太网双向转换器",
        system: "多功能总线控制单元",
        software: "多功能汽车总线开发软件"
    },
    application: {
        title: "设备应用场景及专长",
        title_detail: "我们根据您的项目需求，来开发、设计和制造合适的工具。从残余总线仿真、信号操作、网关配置和数据可视化到数据记录，" +
            "我们的专家将通过软件和硬件为您提供高度复杂的车辆电子和网络技术全方位的支持。",
        small_title: "",
        big_title: "应用场景",
        discover: "探索更多的产品",
        text_1: "残余总线仿真",
        text_2: "网关互联",
        text_3: "信号操控",
        text_4: "数据记录",
        text_5: "自定义编程开发"
    },
    support: {
        title: "技术支持",
        title_detail: "优质的解决方案和服务将为你节省宝贵的时间和预算",
        small_title: "我们的团队",
        big_title: "应用技术支持",
        text_1: "软件/硬件指导技术支持",
        text_2: "技术培训",
        text_3: "产品用户手册",
        text_4: "产品驱动下载",
    },
    contact: {
        title: "获取支持",
        title_detail: "请提供您的需求和疑问，我们会提供合适的解决方案。你可以通过电话、电子邮件或关注微信公众号等方式联系我们",
        card_1: "办公地址",
        card_2: "施达汽车工程咨询(上海)有限公司 \\n" +
            "静安区威海路567号晶采世纪大厦6H,6L \\n " +
            "200041 上海</p>",
        card_3: "工作时间",
        card_4: "9AM - 5PM, 周一至周五",
        card_5: "联系方式",
        card_6: "sales-ee@star-cooperation.cn",
        card_7: "+86 021-8018-3540",
        card_8: "WHAT WE ARE BEST AT",
        card_9: "专业技术支持团队",
    },
    gateway: {
        text_1: "网关",
        text_2: "您想在真实环境中测试 ECU 吗？",
        text_3: "使用不同的通信通道、网络描述格式或开发等级？",
        text_4: "我们为您提供在不同开发阶段将 ECU 集成到现有车辆的解决方案。FL3X 设备集成在 ECU 和样车之间。",
        text_5: "在此，它充当当前系统和新 ECU 之间的中介。最复杂的网络结构或单个网络节点都可以通过 FL3X 设备与配置软件 FL3X Config 进行映射。",
        text_6: "探索相关设备",
    },
    logging: {
        text_1: "数据记录",
        text_2: "任何想要在实验驾驶过程中记录车辆数据的人都需要一个数据记录器。",
        text_3: "这些数据记录器并不一定都是昂贵的大型测量和数据记录系统。",
        text_4: "微型数据记录仪也是非常有用的工具，例如用于车辆安全测量。您可以使用我们的 FL3X 设备记录数据。",
        text_5: "为此，一台 FL3X 设备最多有两个（一个内部和一个外部）microSD 卡插槽。在试车过程中，您可以记录实际的车辆通讯。\n" +
            "              通过使用事件驱动记录，可以将数据量控制在很低的水平。",
        text_6: "探索相关设备",
    },
    restbus: {
        text_1: "残余总线仿真",
        text_2: "您想测试新的控制单元吗?",
        text_3: "这就是残余总线仿真可以帮助您的地方。",
        text_4: "现代汽车正变得越来越复杂,ECU 的数量也在稳步上升。因此,残余总线仿真变得愈发重要。",
        text_5: "使用FL3X设备,您可轻松进行总线仿真:逼真地模拟和测试 ECU 与其他组件的交互,或检查其在整个车辆网络中的" +
            "功能。",
        text_6: "车辆网络中还缺少其他 ECU?没问题!我们的 FL3X 设备也能解决这个问题。结合我们的配置软件 FL3X Config,可以快捷地创建自己的残余总线" +
            "仿真:从单个网络节点的直接仿真到复杂网络的仿真。",
        text_7: "从现在起,您可以在数字孪生系统中对 ECU 进行低成本测试。无需真正的样车:不再需要昂贵、冗长和高风险的测" +
            "试驾驶。",
        text_8: "探索相关设备",
    },
    signal: {
        text_1: "信号操控",
        text_2: "许多 ECU 的关键功能只有在极端情况下进行测试时才会显现出来。",
        text_3: "使用 FL3X 设备，您可以操作所有数据，从而模拟极端情况。这样就可以避免难以模拟而且往往很危险的实际试车。\n" +
            "              当然，操作可以在运行期间激活和调整。在可重复的条件下虚拟测试 ECU。",
        text_4: "连接控制单元 + 加载和操作数据库 = 安全、成功的测试！",
        text_5: "探索相关设备",
    },
    user: {
        text_1: "自定义编程开发",
        text_2: "FL3X 设备的功能还无法完全满足您的需求吗？",
        text_3: "不用担心——您可以扩展现有功能并将自定义代码融入我们的应用中。这样，您就能迅速且轻松地实现那些符合您特定需求的独特应用场景。此外，" +
            "您还可以把自己ECU的新开发软件功能直接集成进FL3X设备，即使在硬件还未到手时也能保证功能的实现。",
        text_4: "连接ECU、加载数据库以及集成个性化代码，让您的测试既个性化又成功！",
        text_5: "探索相关设备",
    },
    training: {
        title_1: "通过FlexAcademy与我们专家一同深入浅出地进行网络技术的教学和探讨",
        title_2: " ",
        title_3: "从STAR® 技术专家的项目经验和网络技术知识中获益，更便捷顺畅地完成您的项目！在FlexAcademy找到适\n" +
            "合您需求的FlexTraining，或通过我们的操作视频获得实际技术支持。",
        subtitle_1: "成为网络技术专家！",
        subtitle_2: "充分体验我们FL3X Training 所带来的优势",
        subtitle_3: "在 FL3X Training课程中，我们逐步教授您FL3X Config软件的所有功能。在我们的 FL3X Training SOME/IP & 以太网研讨会中，\n" +
            "            您将系统学习所有基础知识，并从我们专业的知识和经验中获益。",
        subtitle_4: "联络我们，立即预定您的FL3X Training 课程",
        subtitle_5: "您将获得：",
        subtitle_6: "直接为您提供用户支持",
        subtitle_7: "您可以选择在线学习或参与现场指导（德国）",
        subtitle_8: "我们为您量身定制培训内容",
        subtitle_9: "您将学习到我们的 STAR® 专家提供的专业知识",
        text_1: "无论您面对的任务是关于残余总线模拟、快速原型、网关节点还是信号操纵，FL3X Config都提供了应有的解决方案。\n" +
            "              这项培训将为您提供汽车电子和网络技术基础知识，以及专家的技巧和诀窍，帮助您轻松而有效地在任何情况下使用FL3X Config。",
        text_2: "在我们全新的SOME/IP和以太网培训课程中，我们将为您提供项目所需的技术知识。",
        text_3: "在我们全新的CAN培训课程中，我们将为您提供项目所需的技术知识。",
        text_4: "在我们全新的LIN & SENT培训课程中，我们将为您提供项目所需的技术知识。",
        text_5: "在我们全新的FlexRay培训课程中，我们将为您提供项目所需的技术知识。",
        more: "了解更多",
    },
    user_manuals: "用户操作手册",
    driver: {
        text_1: "驱动下载",
        text_2: "下载我们FL3X产品系列最新版本的免费驱动程序包",
        text_3: "您可以在FL3X_driver_package.zip中的文档 \" FL3X_driver_package_info.pdf \" 中找到关于硬件类型支持的组件的描述。",
        text_4: "您可以在名为 \"FL3X_driver_package_info.pdf\" 的文档中，通过查看 fl3x_driver_package.zip 压缩文件，了解各种硬件类型支持的组件描述。",
        text_5: "您可以在名为 \"FL3X_driver_package_info.pdf\" 的文档中，通过查看 fl3x_driver_package.zip 压缩文件，了解各种硬件类型支持的组件描述。",
        text_6: "您可以在名为 \"FL3X_driver_package_info.pdf\" 的文档中，通过查看 fl3x_driver_package.zip 压缩文件，了解各种硬件类型支持的组件描述。",
        history: "历史版本"
    },
    can: {
        text_1: "我们为您提供必要的专业知识！在我们的一天活动中学习有关控制器局域网（CAN）的一切。然后，您将收到以英文显示的文件。\n" +
            "        您还可以决定您个人的培训将在哪里进行：在德国的STAR®地点、在您的现场或在线。",
        text_2: "培训内容",
        text_3: "针对汽车领域的CAN总览",
        text_4: "CAN物理层",
        text_5: "CAN总线（CAN High和CAN Low）",
        text_6: "CAN总线逻辑（Dominant和Recessive）",
        text_7: "CAN总线差分电压",
        text_8: "CAN收发器和CAN控制器",
        text_9: "CAN总线终端电阻",
        text_10: "CAN端口",
        text_11: "基于CAN的演进",
        text_12: "CAN数据传输数据链路层",
        text_13: "按位仲裁",
        text_14: "标准/扩展经典CAN/CAN FD帧格式",
        text_15: "CAN帧类型",
        text_16: "CAN/CAN-FD中的非返回零（NRZ）与比特填充",
        text_17: "CAN比特定时",
        text_18: "比特时间",
        text_19: "时间量子",
        text_20: "比特时间分割",
        text_21: "边缘相位误差",
        text_22: "CAN错误处理",
        text_23: "错误计数器（TEC和REC）",
        text_24: "错误状态（错误激活和错误被动以及总线关闭）",
        text_25: "错误隔离",
        text_26: "错误检测机制",
        text_27: "CAN XL基础",
        text_28: "针对汽车领域的CAN XL概述",
        text_29: "CAN协议里程碑",
        text_30: "CAN XL在OSI参考模型中的位置",
        text_31: "CAN XL核心属性",
        text_32: "CAN XL数据传输/CAN XL错误处理",
        text_33: "CAN XL数据帧格式",
        text_34: "CAN XL上的IP概念",
        text_35: "将CAN XL集成到以太网网络中",
        text_36: "将嵌入式IP数据包放入CAN XL帧中",
        text_37: "CAN XL安全性（CANsec）",
        text_38: "CAN XL错误处理",
        text_39: "CAN XL错误检测机制",
        text_40: "错误机制",
        text_41: "错误类型",
        text_42: "格式检查",
        text_43: "CRC结果",
        text_44: "比特监视",
        text_45: "您将获得：",
        text_46: "为期1天的短期培训",
        text_47: "大量的实践练习",
        text_48: "适合初学者以及高级用户",
        text_49: "许多使用工具的有效技巧和窍门",
        text_50: "解决策略和解释定义复杂问题的内部机制的说明",
        text_51: "是否感兴趣？",
        text_52: "请查看我们培训课程的详细描述，包括课程时间表。或者直接联系我们，获取您个人的FL3X培训报价。",
        text_53: "下载说明和时间表",
    },
    config: {
        text_1: "FL3X Config软件为复杂的网络技术提供解决方案。无论您面对的是什么任务——残余总线仿真、\n" +
            "      快速原型、网关节点还是信号操纵——FL3X Config都有合适的解决方案。FL3X Config直观易用，\n" +
            "      可根据您的具体用例进行调整。",
        text_2: "此培训将提供所有基础知识，以及一些技巧和窍门，帮助您在任何情况下轻松有效地使用FL3X Config*。\n" +
            "      我们将采用一步一步的方法，向您展示使用我们工具的简便路径。凭借您独家的专家知识，我们将深入引导您了解我们的用户软件。"
    },
    ray: {
        text_1: "我们为您提供必要的专业知识！在我们的一天活动中学习关于FlexRay的一切。您将收到以英文显示的文件。\n" +
            "      您还可以决定您个人的培训将在哪里进行：在德国的STAR®地点、在您的现场或在线。",
        text_2: "培训内容 FlexRay",
        text_3: "FlexRay在汽车领域的概述",
        text_4: "FlexRay物理层",
        text_5: "拓扑变体",
        text_6: "网络组件",
        text_7: "- 电缆、端口、终端",
        text_8: "总线驱动器",
        text_9: "主动星型节点",
        text_10: "通信控制器",
        text_11: "总线守护者",
        text_12: "FlexRay协议",
        text_13: "FlexRay电控单元的组件",
        text_14: "传输结构",
        text_15: "FlexRay访问结构",
        text_16: "帧格式",
        text_17: "帧编码",
        text_18: "同步",
        text_19: "FlexRay唤醒",
        text_20: "FlexRay启动",
        text_21: "与FlexRay相关的项目",
        text_22: "AUTOSAR",
        text_23: "FIBEX",
        text_24: "您将获得：",
        text_25: "短时1天培训",
        text_26: "大量的实践练习",
        text_27: "适合初学者和高级用户",
        text_28: "是否感兴趣？",
        text_29: "那么请查看我们培训课程的详细描述，包括时间表。或者直接联系我们，获取您个人的FL3X培训报价。",
        text_30: "下载说明和时间表",

    },
    lin: {
        text_1: "我们为您提供必要的专业知识！在我们的一天活动中学习关于LIN和SENT的一切。您将收到以英文显示的文件。" +
            "您还可以决定您个人的培训将在哪里进行：在德国的STAR®地点、在您的现场或在线。",
        text_2: "针对汽车领域的LIN总览",
        text_3: "LIN物理层",
        text_4: "LIN总线逻辑（Dominant和Recessive）",
        text_5: "LIN总线电压",
        text_6: "LIN收发器",
        text_7: "LIN串行通道",
        text_8: "LIN端口",
        text_9: "示波器中的LIN总线错误分析",
        text_10: "LIN数据传输",
        text_11: "LIN主/从任务",
        text_12: "LIN调度表原理",
        text_13: "LIN帧格式",
        text_14: "LIN帧类型",
        text_15: "LIN帧头",
        text_16: "LIN帧响应",
        text_17: "LIN通信工作流程",
        text_18: "LIN数据帧格式中的PID/校验和计算",
        text_19: "LIN错误处理",
        text_20: "LIN错误检测机制",
        text_21: "针对汽车领域的SENT总览",
        text_22: "SENT物理层",
        text_23: "SENT物理层拓扑结构",
        text_24: "SENT端口",
        text_25: "总线布线的严酷环境和ECU端口",
        text_26: "SENT快速/慢速通道传输的示波器跟踪",
        text_27: "SENT数据传输",
        text_28: "SENT术语的基本定义",
        text_29: "SENT快速通道数据帧格式",
        text_30: "短串行消息",
        text_31: "增强型串行消息",
        text_32: "SENT格式的数据编码方法"
    },
    some: {
        text_1: "在我们的新培训课程SOME/IP & Ethernet中，我们将为您提供所需的技术知识。无论是用德语还是英语，" +
            "您可以选择培训使用的语言。您将收到英文文档。您还可以决定个人培训的地点：在德国的STAR®现场、在您的场地，或者在线上。",
        text_2: "我们将提供以太网的基础理解，以便您能够识别并避免在处理通信技术时可能遇到的一些陷阱。" +
            "OSI模型由七个层级组成，每个层级都有明确定义的任务，并与下一层级有清晰的通道。该模型确保在理想情况下可以替换单个层级。",
        text_3: "Ethernet在车辆中用于各种功能，如诊断、测量和校准。电动车辆和充电站的控制单元在整个充电周期内都会相互通信。",
        text_4: "在自动化和自主驾驶车辆中使用以太网作为总线系统已经变得不可或缺。",
        text_5: "在我们的FlexTraining课程中，我们可以阐明这些以及其他问题。",
        text_6: "车辆通信的快速发展允许新功能被整合，并为未来铺平道路。",
        text_7: "如今，由于SOME/IP相对于传统通信所提供的各种优势，它被发现存在于各种系统中，如信息娱乐控制台、" +
            "高级驾驶辅助系统（ADAS）、摄像头等等。",
        text_8: "我们将整体看待这些优势。",
        text_9: "面向服务的架构（SOA）",
        text_10: "面向服务的通信与基于信号的通信",
        text_11: "SOME/IP集成",
        text_12: "客户端/服务器模型",
        text_13: "SOME/IP服务发现消息（消息头、消息条目、消息选项）",
        text_14: "SOME/IP服务发现时序",
        text_15: "SOME/IP事件/字段/方法",
        text_16: "SOME/IP序列化/反序列化",
        text_17: "SOME/IP消息容器",
        text_18: "SOME/IP TP-传输协议",
        text_19: "通过SecOC（安全机载通信）保护SOME/IP",
        text_20: "分为理论和实践部分"
    },
    compare: {
        introduction: "点击观看 FL3X Devices 简介",
        details: "产品详情",
        tech: "技术参数",
        software_support: "软件支持",
        electric_support: "电气特性",
        physical_support: "物理特性",
        environment: "环境条件",
        device: {
            pro: {
                text_1: "2*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "2 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达8个FlexRay控制器",
                text_7: "多达10条FlexRay总线通道",
                text_8: "多达20个CAN-HS总线通道",
                text_9: "多达20个CAN-FD总线通道",
                text_10: "支持唤醒/睡眠模式",
                text_11: "可通过FlexRay或CAN唤醒",
                text_12: "多达10个100 MBit/s的以太网总线通道",
                text_13: "多达10个100BASE-T1车载以太网总线通道",
                text_14: "多达7个1000BASE-T1车载以太网总线通道",
                text_15: "1 GBit/s以太网配置通道",
                text_16: "集成1 GBit/s以太网交换机",
                text_17: "一个内部和一个外部的Micro SD卡插槽",
                text_18: "4个数字输入或输出（DIO）",
                text_19: "5个模拟或数字输入（ADI）",
                text_20: "集成Wifi模块（b/n/g）",
                text_21: "集成蓝牙模块",
                text_22: "4.2 BR/EDR BLE双模控制器",
                text_23: "FL3X Config支持该设备",
                text_24: "Flex Config RBS 5.0版本及以上",
                text_25: "电源电压：8-42 VDC",
                text_26: "电源电流（睡眠模式）：<5mA(@12V)",
                text_27: "电源电流（运行模式）：<600mA(@12V)",
                text_28: "电源：Binder 702/712系列 - 2针",
                text_29: "DIO, ADI：Binder 702/712系列 - 7针",
                text_30: "以太网, 端口1-5：Binder 702/712系列 - 8针",
                text_31: "重量约为：975g",
                text_32: "尺寸 长*宽*高：241*124*36mm",
                text_33: "温度：",
                text_34: "运行/存储：-40℃ 至 +85℃"
            },
            normal: {
                text_1: "2*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "2 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达6个FlexRay控制器",
                text_7: "多达8条FlexRay总线通道",
                text_8: "多达15个CAN-HS总线通道",
                text_9: "多达10个CAN-FD总线通道",
                text_10: "支持唤醒/睡眠模式",
                text_11: "可通过FlexRay或CAN唤醒",
                text_12: "多达10个100 MBit/s的以太网总线通道",
                text_13: "多达10个100BASE-T1车载以太网总线通道",
                text_14: "多达7个1000BASE-T1车载以太网总线通道",
                text_15: "1 GBit/s以太网配置通道",
                text_16: "集成1 GBit/s以太网交换机",
                text_17: "一个内部和一个外部的Micro SD卡插槽",
                text_18: "4个数字输入或输出（DIO）",
                text_19: "5个模拟或数字输入（ADI）",
                text_20: "集成Wifi模块（b/n/g）",
                text_21: "集成蓝牙模块",
                text_22: "4.2 BR/EDR BLE双模控制器",
                text_23: "FL3X Config支持该设备",
                text_24: "Flex Config RBS 5.0版本及以上",
                text_25: "电源电压：8-42 VDC",
                text_26: "电源电流（睡眠模式）：<5mA(@12V)",
                text_27: "电源电流（运行模式）：<600mA(@12V)",
                text_28: "电源：Binder 702/712系列 - 2针",
                text_29: "DIO, ADI：Binder 702/712系列 - 7针",
                text_30: "以太网, 端口1-5：Binder 702/712系列 - 8针",
                text_31: "重量约为：975g",
                text_32: "尺寸 长*宽*高：241*124*36mm",
                text_33: "温度：",
                text_34: "运行/存储：-40℃ 至 +85℃"
            },
            s: {
                text_1: "1*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达4个FlexRay控制器",
                text_7: "多达8条FlexRay总线通道",
                text_8: "多达8个CAN-HS总线通道",
                text_9: "多达6个CAN-FD总线通道",
                text_10: "支持唤醒/睡眠模式",
                text_11: "可通过FlexRay或CAN唤醒",
                text_12: "多达2个100 Mbit/s的以太网总线通道",
                text_13: "多达3个100BASE-T1车载以太网总线通道",
                text_14: "多达2个1000BASE-T1车载以太网总线通道",
                text_15: "1 Gbit/s以太网配置通道",
                text_16: "集成1 Gbit/s以太网交换机",
                text_17: "一个内部的和一个外部的Micro SD卡插槽",
                text_18: "4个数字输入或输出（DIO）",
                text_19: "5个模拟或数字输入（ADI）",
                text_20: "集成Wifi模块(b/n/g)",
                text_21: "集成蓝牙模块",
                text_22: "4.2 BR/EDR BLE双模控制器",
                text_23: "FL3X Config支持该设备",
                text_24: "Flex Config RBS 5.0版本及以上",
                text_25: "电源电压: 8-42 VDC",
                text_26: "电源电流(睡眠模式): <5mA(@12V)",
                text_27: "电源电流(运行模式): <500mA(@12V)",
                text_28: "电源: Binder 702/712系列 - 2针",
                text_29: "ADIO: D-Sub Female – 9针",
                text_30: "以太网,端口1-3: RJ45 – 8针./D-Sub male – 9针",
                text_31: "重量约为: 655g",
                text_32: "尺寸 长*宽*高: 166*124*36mm",
                text_33: "温度:",
                text_34: "运行/存储: -40℃ 至 +85℃"
            },
            pxie: {
                text_1: "1*Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口可配置2-6个总线通道",
                text_6: "多达4个FlexRay控制器",
                text_7: "多达8条FlexRay总线通道",
                text_8: "多达10个CAN-HS总线通道",
                text_9: "多达10个CAN-FD总线通道",
                text_10: "唤醒/睡眠模式",
                text_11: "可通过FlexRay或CAN唤醒",
                text_12: "多达7个100 MBit/s以太网总线通道",
                text_13: "多达7个100BASE-T1总线通道",
                text_14: "--",
                text_15: "1 GBit/s以太网配置通道",
                text_16: "集成1 GBit/s以太网交换机",
                text_17: "--",
                text_18: "2个数字输入或输出（DIO）",
                text_19: "--",
                text_20: "--",
                text_21: "--",
                text_22: "--",
                text_23: "该设备支持FL3X Config",
                text_24: "从Flex Config RBS 5.0版本开始支持",
                text_25: "供电电压：8-42 VDC",
                text_26: "睡眠模式下电流：<5mA(@12V)",
                text_27: "运行模式下电流：<600mA(@12V)",
                text_28: "电源：Binder 702/712系列-2针",
                text_29: "DIO, ADI：Binder 702/712系列-7针",
                text_30: "大约重量：180g",
                text_31: "尺寸（PXIe）长宽高：210*130*3U",
                text_32: "尺寸（PCIe）长宽高：172*125*18",
                text_33: "温度：",
                text_34: "运行/存储温度：-40°C至+85°C"
            }
        },
        interface: {
            l2: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达4个FlexRay控制器",
                text_7: "多达8条FlexRay总线通道",
                text_8: "多达15个CAN-HS总线通道",
                text_9: "多达10个CAN-FD总线通道",
                text_10: "1 GBit/s以太网配置通道",
                text_11: "集成WiFi通道",
                text_12: "5个FL3X Tiny3 可变物理层插槽",
                text_13: "5个M9 通道",
                text_14: "电源电压: 8-42 VDC",
                text_15: "电源电流(睡眠模式): <5mA(@12V)",
                text_16: "电源电流(运行模式): <600mA(@12V)",
                text_17: "电源: Binder 702/712系列-2针",
                text_18: "DIO,ADI: Binder 702/712系列-7针",
                text_19: "以太网, 端口1-5: Binder 702/712系列-8针",
                text_20: "重量约为: 975g",
                text_21: "尺寸 长*宽*高: 241*124*36mm",
                text_22: "温度:",
                text_23: "运行/存储: -40℃ 至 +85℃",
                text_24: "IP-Code: IP20(根据需求可供IP67)",
                text_25: "相对湿度: 运行/存储: 0% 至 90%RH, 无冷凝"
            },
            l: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "5个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达4个FlexRay控制器",
                text_7: "多达8条FlexRay总线通道",
                text_8: "多达15个CAN-HS总线通道",
                text_9: "多达10个CAN-FD总线通道",
                text_10: "1 GBit/s以太网配置通道",
                text_11: "集成WiFi通道",
                text_12: "5个FL3X Tiny3 可变物理层插槽",
                text_13: "5个M9通道",
                text_14: "电源电压: 8-42 VDC",
                text_15: "电源电流（睡眠模式）: <5mA(@12V)",
                text_16: "电源电流（运行模式）: <600mA(@12V)",
                text_17: "电源: Binder 702/712系列-2针",
                text_18: "DIO, ADI: Binder 702/712系列-7针",
                text_19: "以太网, 端口1-5: Binder 702/712系列-8针",
                text_20: "重量约为: 955g",
                text_21: "尺寸 长*宽*高: 241*124*36mm",
                text_22: "温度:",
                text_23: "运行/存储: -40℃ 至 +85℃",
                text_24: "IP等级: IP20（可按需提供IP67）",
                text_25: "相对湿度: 运行/存储: 0% 至 90%RH, 无冷凝"
            },
            s: {
                text_1: "Altera Cyclone V SOC",
                text_2: "ARM Cortex A9 双核 CPU",
                text_3: "1 GByte DDR3 RAM",
                text_4: "3个总线端口",
                text_5: "每个总线端口上可配置2-6个总线通道",
                text_6: "多达4个FlexRay控制器",
                text_7: "多达2条FlexRay总线通道",
                text_8: "多达8个CAN-HS总线通道",
                text_9: "多达6个CAN-FD总线通道",
                text_10: "1 GBit/s以太网配置通道",
                text_11: "集成WiFi通道",
                text_12: "FL3X Tiny3 可变物理层插槽",
                text_13: "3个SUB-D 通道",
                text_14: "电源电压: 8-42 VDC",
                text_15: "电源电流(睡眠模式): <10mA(@12V)",
                text_16: "电源电流(运行模式): <500mA(@12V)",
                text_17: "电源: Binder 702/712系列-2针",
                text_18: "ADIO: D-Sub 母头-9针",
                text_19: "以太网, Con1-3: RJ45-8针, D-Sub公头-9针",
                text_20: "重量约: 665g",
                text_21: "尺寸 长*宽*高: 166*124*36mm",
                text_22: "温度:",
                text_23: "运行/存储: -40℃ 至 +85℃",
                text_24: "IP-Code: IP20",
                text_25: "相对湿度: 运行/存储: 0% 至 90%RH, 无冷凝"
            }
        },
        media: {
            text_1: "技术特点",
            text_2: "电气特性",
            text_3: "物理特性",
            text_4: "工作电压：",
            text_5: "绝对最大值(非工作状态)：",
            text_6: "电源电流",
            text_7: "重量约",
            text_8: "尺寸 长×宽×高",
            text_9: "工作温度：",
            text_10: "非工作温度：",
            text_11: "存储温度：",
            text_12: "相对湿度：",
            text_13: "环境条件",
        }
    },
    product_detail: {
        normal: {
            description: "产品简介",
            information: "产品信息",
            advantages: "产品优势",
            tech: "技术参数",
            download: "驱动/文档下载",
            spechsheet: "产品规格表",
            number: "型号",
        },
        device: {
            l2: {
                text_1: "旗舰级多功能总线控制单元 FlexDevice-L² 配备了多个可配置通道, 能够支持当前和即将上线的各种总线系统, 满足大多数应用\n" +
                    "              领域对总线的使用需求。本设备方便实现以及测试复杂的网关应用和原型设计功能, 因此受到嵌入式软件开发人员的广泛好评。",
                desc: "FlexDevice-L² 是 FL3X Device 系列产品中的旗舰产品，众多汽车制造商和零部件供应商在汽车电气架构开发过程中均使用此产品。\n" +
                    "            FL3X Device 系列产品提供以下多个解决方案：",
                ad_li_1: "2* ARM Cortex A9 双核（800 MHz），带有 2GB DDR3 RAM",
                ad_li_2: "最多 30 个总线通道（通过可插拔收发器）",
                ad_li_3: "最多 10 个以太网通道",
                ad_li_4: "最多 20 个 CAN 通道",
                ad_li_5: "最多 10 个 FlexRay 通道（5* A 通道和 5* B 通道）",
                ad_li_6: "集成 WiFi 和蓝牙模块",
                ad_li_7: "唤醒/睡眠模式支持",
                ad_li_8: "启动 < 200ms ",
                ad_li_9: "温度范围 -40°C 到 +85°C",
                tech_1_li_1: "2* Altera Cyclone V SOC（集成 ARM Cortex A9 双核 CPU）",
                tech_1_li_2: "2GB DDR3 RAM",
                tech_1_li_3: "5 个总线端口，可以通过可插拔收发器灵活分配",
                tech_1_li_4: "每个总线端口上有 2-6 个总线通道",
                tech_1_li_5: "最多 8 个 FlexRay 控制器（8 个Bosch E-Ray 核心）",
                tech_1_li_6: "最多 10 个 FlexRay 通道（5 个 A 通道和 5 个 B 通道）",
                tech_1_li_7: "在任何情况下，可以内部调用 2 个 FlexRay 核心（自同步）",
                tech_1_li_8: "最多 20 个 CAN-HS 总线通道",
                tech_1_li_9: "最多 20 个 CAN-FD 总线通道",
                tech_1_li_10: "唤醒/睡眠支持（可以通过 FlexRay 或 CAN 进行唤醒）",
                tech_1_li_11: "最多 10 个 100 MBit/s 以太网总线通道",
                tech_1_li_12: "最多 10 个 100BASE-T1 以太网总线通道",
                tech_1_li_13: "1 GBit/s 以太网配置通道",
                tech_1_li_14: "集成 1 GBit/s 以太网交换机",
                tech_1_li_15: "一个内部和一个外部 Micro SD 卡槽",
                tech_1_li_16: "4 个数字输入或输出（DIO）",
                tech_1_li_17: "5 个模拟或数字输入（ADI）",
                tech_1_li_18: "集成 WiFi 模块（b/n/g）",
                tech_1_li_19: "集成蓝牙模块（4.2 BR/EDR BLE 双模控制器）"
            },
            l: {
                text_1: "旗舰级多功能总线控制单元 FlexDevice-L 配备了多个可配置通道, 能够支持当前和即将上线的各种总线系统, 满足大多数应用\n" +
                    "              领域对总线的使用需求。本设备方便实现以及测试复杂的网关应用和原型设计功能, 因此受到嵌入式软件开发人员的广泛好评。",
                desc_1: "FlexDevice-L 是 FlexDevice 产品系列的旗舰产品，被许多汽车制造商和零部件供应商在其汽车电子开发中广泛使用和认可。FlexDevice 系列提供了多种解决方案：",
                desc_2: "不同汽车总线系统之间的网关",
                desc_3: "总线通道",
                desc_4: "残余总线仿真",
                desc_5: "信号操作",
                desc_6: "数据记录",
                desc_7: "快速原型",
                ad_li_1: "通过可插拔收发器灵活分配的 5 个总线连接（每个总线端口上有 2-6 个总线通道）",
                ad_li_2: "可以达到以下最大通道*：4 个 FlexRay 控制器（4 个Bosch E-Ray 核心）；8 个 FlexRay 通道；10 个 CAN-FD / 15 个 CAN-FS；7 个汽车以太网 1000BASE-T1；10 个汽车以太网 100BASE-T1；30 个 SENT/LIN -（* 取决于可插拔收发器和可用 FPGA-Image组合）",
                ad_li_3: "ARM Cortex A9 双核（800 MHz），带 1GB DDR3 RAM",
                ad_li_4: "集成 WIFI 模块",
                ad_li_5: "支持唤醒/睡眠模式",
                ad_li_6: "启动 < 200ms ",
                ad_li_7: "温度范围 -40°C 到 +85°C",
                tech_1_li_1: "Altera Cyclone V SOC（集成 ARM Cortex A9 双核 CPU）",
                tech_1_li_2: "2GB DDR3 RAM",
                tech_1_li_3: "5 个总线端口，可以通过可插拔收发器灵活分配",
                tech_1_li_4: "每个总线端口上有 2-6 个总线通道",
                tech_1_li_5: "最多 4 个 FlexRay 控制器（4 个Bosch E-Ray 核心）",
                tech_1_li_6: "总线端口上可用的最多 8 个 FlexRay 通道（4 个 A 通道和 4 个 B 通道）",
                tech_1_li_7: "在任何情况下，可以内部调用 2 个 FlexRay 核心（自同步）",
                tech_1_li_8: "最多 15 个 CAN-HS 总线通道",
                tech_1_li_9: "最多 10 个 CAN-FD 总线通道",
                tech_1_li_10: "唤醒/睡眠支持（可以通过 FlexRay 或 CAN 进行唤醒）",
                tech_1_li_11: "最多 10 个 100 MBit/s 以太网总线通道",
                tech_1_li_12: "最多 10 个 100BASE-T1 以太网总线通道",
                tech_1_li_13: "最多 7 个 1000BASE-T1 以太网总线通道",
                tech_1_li_14: "1 GBit/s 以太网配置通道",
                tech_1_li_15: "集成 1 GBit/s 以太网交换机",
                tech_1_li_16: "一个内部和一个外部 Micro SD 卡槽",
                tech_1_li_17: "4 个数字输入或输出（DIO）",
                tech_1_li_18: "5 个模拟或数字输入（ADI）",
                tech_1_li_19: "集成 WiFi 模块（b/n/g）",
                tech_1_li_20: "集成蓝牙模块（4.2 BR/EDR BLE 双模控制器）"
            },
            s: {
                text_1: "旗舰级多功能总线控制单元 FlexDevice-S 配备了多个可配置通道, 能够支持当前和即将上线的各种总线系统, 满足大多数应用\n" +
                    "              领域对总线的使用需求。本设备方便实现以及测试复杂的网关应用和原型设计功能, 因此受到嵌入式软件开发人员的广泛好评。",
                desc: "FlexDevice-S 是 FL3X Device 系列产品中的初级产品，众多汽车制造商和零部件供应商在汽车电气架构开发过程中均使用此产品。\n" +
                    "            FL3X Device 系列产品提供一下多个解决方案：",
                "ad_li_1": "1* ARM Cortex A9 双核（800 MHz），带有 1GB DDR3 RAM",
                "ad_li_2": "最多 14 个总线通道",
                "ad_li_3": "2* CAN-HS/FD 通道",
                "ad_li_4": "1* FlexRay A 通道（SelfSync）*",
                "ad_li_5": "1* FlexRay B 通道（SelfSync）*",
                "ad_li_6": "1* 100BASE-T1 通道*",
                "ad_li_7": "1* LIN 通道",
                "ad_li_8": "1个灵活的FL3X Tiny3插槽，用于额外的总线/网络收发器（2x CAN-FD/HS 或 4x CAN-FD/HS 或 2 x FlexRay (1 A通道 / 1 B通道) 或 2 x 100BASE-T1 或 4 x LIN 和 2 x SENT- 通道）",
                "ad_li_9": "集成 WIFI 模块",
                "ad_li_10": "用于汽车总线系统的D-sub端口",
                "ad_li_11": "唤醒/睡眠模式支持",
                "ad_li_12": "启动 < 200 ms ",
                "ad_li_13": "Micro SD 卡槽",
                "ad_li_14": "IP20",
                "ad_li_15": "温度范围 -40°C 到 +85°C",
                "ad_li_16": "* 基本单元提供了CAN-HS和LIN总线系统。要使用FlexRay、CAN-FD或100BASE-T1总线系统，需要额外的许可证。",
                "tech_li_1": "Altera Cyclone V SOC（集成 ARM Cortex A9 双核 CPU）",
                "tech_li_2": "1GB DDR3 RAM",
                "tech_li_3": "3个D-sub总线端口，带有固定和可插拔收发器 - 每个端口上最多6个总线通道",
                "tech_li_4": "支持的汽车总线系统 - 每个端口上最多4个FlexRay控制器（Bosch E-Ray 核心） - 每个端口上最多2个FlexRay通道（2* A通道和2 B通道）",
                "tech_li_5": "带有2个FlexRay核心的端口提供自同步 - 每个端口上最多6个CAN-FD控制器* - 每个端口上最多8个CAN-HS控制器* - 每个端口上最多3个100 MBit/s以太网控制器*",
                "tech_li_6": "唤醒/睡眠模式支持（可以通过FlexRay或CAN进行唤醒）",
                "tech_li_7": "通过RJ45的1 GBit/s以太网配置通道",
                "tech_li_8": "集成 1 GBit/s 以太网交换机",
                "tech_li_9": "Micro SD 卡槽",
                "tech_li_10": "通过D-sub的4个数字输入/输出或模拟输入（ADIO）",
                "tech_li_11": "* 可以使用多种总线组合，请联系我们以获取您所需的总线配置。"
            },
            pxie: {
                text_1: "FL3X Device-PXIe/PCIe 作为高性能平台综合了实时残余总线仿真和网关，将多达 30 个通道的汽车总线接口组合到一个设备中。",
                desc: "FL3X Device-PXIe/PCIe作为高性能平台，支持实时残余总线仿真或网关功能，并配备多达30个通道*的测量通道，\n" +
                    "            专为汽车总线系统设计。此设备提供了一套多功能解决方案，能够满足汽车行业对高精度测试和数据采集的复杂需求：",
                desc_1: "FL3X Device-PXIe/PCIe为当前及未来的汽车总线系统提供了一种高性能支持平台，它集成了多种可配置通道，满足了广泛的应用需求，\n" +
                    "            尤其是在需要精密总线通道的场合。这款设备因其在实现和测试复杂网关应用与原型开发方面的高效能力，而成为汽车开发领域内专业人士的首选。\n" +
                    "            通过配合FL3X Config软件套件，该设备使开发人员能够高效地配置、管理，以及优化他们的总线系统与电子控制单元(ECU)环境，\n" +
                    "            进一步提升了开发过程的智能化和灵活性。",
                "ad_li_1": "5* 可插拔收发器模块（每个模块的通道数取决于总线系统）",
                "ad_li_2": "可用于CAN-HS、CAN-FD、FlexRay、100BASE-TX、LIN、SENT、100BASE-T1、1000BASE-T1的可插拔收发器模块",
                "ad_li_3": "提供PXIe和PCIe规格的接口结构",
                "ad_li_4": "可用作实时网关/残余总线仿真，并将所有通道并行传输至PCIe主机系统",
                "ad_li_5": "可为大多数总线接口生成同步时间戳（分辨率高达1us）",
                "tech_li_1": "PCIe Gen 1",
                "tech_li_2": "集成 Altera Cyclone V SOC（ARM Cortex-A9 双核 CPU）",
                "tech_li_3": "5个总线端口，可以通过可插拔收发器灵活分配 - 每个总线端口上可用的总线通道为2-6个",
                "tech_li_4": "最多4个FlexRay控制器（4个Bosch E-Ray 核心）*",
                "tech_li_5": "最多8个FlexRay通道（4个A通道和B通道）可用于总线端口*",
                "tech_li_6": "在任何情况下，2个FlexRay核心都可以内部连接（自同步）",
                "tech_li_7": "最多10个CAN-HS总线通道*",
                "tech_li_8": "最多10个CAN-FD总线通道*",
                "tech_li_9": "最多7个独立的100BASE-T1总线接口或15个内部连接（切换）的100BASE-T1通道*",
                "tech_li_10": "可配置终端电阻",
                "tech_li_11": "唤醒/睡眠模式支持（可以通过FlexRay或CAN进行唤醒）",
                "tech_li_12": "所有总线通道的同步时间戳生成（分辨率高达1微秒）",
                "tech_li_13": "2个触发输入或输出",
                "tech_li_14": "3种不同的操作模式： - 纯FlexCard模式。通过Windows驱动程序在PCIe主机系统上发送/接收总线数据 - 纯FlexDevice模式。执行实时残余总线仿真和网关 - 混合模式。在集成的ARM Cortex-A9 CPU上执行实时残余总线仿真，并在PCIe主机系统（PC）上接收所有总线数据",
                "tech_li_15": "在集成的ARM Cortex-A9 CPU上执行的网关和/或残余总线仿真，而不影响PCIe主机系统（PC）",
                "tech_li_16": "在不影响在ARM Cortex-A9 CPU上执行的网关/残余总线仿真的情况下，将总线数据流传输至PCIe主机系统",
                "tech_li_17": "* 可以使用多种总线组合，请联系我们以获取您所需的总线配置。"
            },
        },
        config: {
            text: "FL3X Config 是一款多功能汽车总线开发配置软件，支持多种网络描述格式。生成的RBS可以独立的硬件上运行。",
            desc: "FL3X Config 是一款多功能汽车总线开发配置软件。它支持多种网络描述格式，包括 AUTOSAR、FIBEX、LDF 和 CANdb。\n" +
                "            生成的 RBS 在独立的硬件上独立运行。FL3X Config 使您能够轻松记录、分析和追踪您的总线数据。",
            "ad_li_1": "多个总线系统的网关生成，例如 CAN-HS/FD、FlexRay、100BASE-T1、1000BASE-T1、10BASE-TX、10BASE-T1S、LIN、SENT",
            "ad_li_2": "信号操纵：无需编码",
            "ad_li_3": "残余总线仿真（RBS）",
            "ad_li_4": "易于使用的 GUI：两分钟配置",
            "ad_li_5": "同步的 FlexRay/FlexRay 网关",
            "ad_li_6": "支持多种设备：所有实际的 FlexDevices 和 FlexCards",
            "tech_p_1": "用户定义的 CRC 算法、活动/消息计数器和网络管理通道（ANSI-C 代码）",
            "tech_p_2": "为多个 OEM 提供 E2E 保护",
            "tech_p_3": "通过 Windows API 和以太网访问 RBS 运行时参数：",
            "tech_li_1": "全局参数（读/写）",
            "tech_li_2": "信号（读/写）",
            "tech_li_3": "PDU（读/写）",
            "tech_li_4": "总线控制器（开关/读取状态）",
            "tech_li_5": "模拟的 ECU（开关/读取状态）",
            "tech_p_4": "FlexConfig RBS 包括用于实时操作的 FlexConfig 控制",
            "tech_p_5": "运行在 Windows 10 (64-bit) 上",
            "tech_p_6": "用户功能编辑器",
            "tech_li_6_1": "基于帧和基于 PDU 的 TX 和 RX 函数的实现，支持拖放和用户定义的算法。",
            "tech_li_6_2": "基于中断的 CycleStartInterrupt 函数的实现，支持拖放和用户定义的算法",
            "tech_li_6_3": "可以包含用户定义的 .c/.h 文件和 .a 库",
            "tech_li_6_4": "通过 WindowsAPI 和以太网在运行时访问 TX 或 RX 主要函数",
            "tech_li_6_5": "支持拖放和集成 I/O",
            "tech_li_6_6": "具有存储功能的信号和基于帧的数据处理",
            "tech_li_6_7": "RX 用户功能：访问最后接收到的帧的有效载荷（例如用于 CRC 检查）",
            "tech_li_6_8": "为模拟的 TX 帧生成额外的 TX 事件（例如用于传输不具有循环触发时序的 CAN 帧）",
            "tech_li_6_9": "读/写访问 PDU 更新位",
            "tech_li_6_10": "启用/禁用模拟的 ECU",
            "tech_li_6_11": "启用/禁用总线",
            "tech_li_6_12": "访问用户定义",
            "tech_li_6_13": "支持用户定义的同步/启动帧",
            "tech_li_6_14": "支持 DoIP 和 ISO-TP（根据请求）",
            "tech_li_6_15": "支持 LIN"
        },
        interface: {
            l2: {
                text: "FL3X Interface-L²是FL3X通道系列中的高级解决方案，它提供了一个1000BASE-T上行端口，\n" +
                    "              用于连接记录仪或测量计算机。该通道既可以配合标准CMP或PLP记录协议用于数据记录设备，\n" +
                    "              也可以作为一个全功能的通道，支持读写操作，适用于我们提供的Linux和Windows驱动程序。",
                desc_1: "FL3X Interface 系列提供了一种高度灵活的汽车总线通道解决方案，专为数据采集设备和个人计算机设计。\n" +
                    "            它通过采用可插拔的物理层模块和应用FPGA，支持目前及未来的总线和网络系统。",
                desc_2: "FL3X Interface-L²搭载了两个FPGA，主要用于管理各种总线系统，并配备了五个FL3X Tiny3插槽，\n" +
                    "            用于安装可插拔的收发器，这些收发器能够支持FlexRay、CAN-HS/FD/SIC以及LIN/SENT通道。\n" +
                    "            得益于其灵活的Tiny插槽设计和FPGA的强大功能，它可以轻松扩展包括现有的和即将推出的FL3X\n" +
                    "            Tiny3及其通信通道。",
                "ad_li_1": "五个可插拔收发器模块（每个模块根据总线系统的不同可有 2-6 个通道）",
                "ad_li_2": "可用于 CAN-FS、CAN-FD、CAN-FD SIC、FlexRay、LIN、SENT*、KLine*、RS232*、SPI* 的可插拔收发器模块",
                "ad_li_3": "紧凑的外形尺寸",
                "ad_li_4": "大多数总线通道的同步时间戳生成（分辨率高达 1μs）",
                "ad_li_5": "配置总线终端",
                "ad_li_6": "博世 E-Ray IP 核 FlexRay 控制器",
                "ad_li_7": "博世 D-CAN IP 核 CAN 控制器",
                "ad_li_8": "博世 M-CAN IP 核 CAN-FD 控制器",
                "ad_li_9": "英特尔三速以太网以太网控制器",
                "ad_li_10": "V2.1 A FlexRay 协议规范",
                "ad_li_11": "V2.0 A/B CAN 协议规范",
                "ad_li_12": "ISO 11898-1:2015 CAN 协议规范",
                "ad_li_13": "博世 CAN-FD 规范 1.0 （*API 可能，但目前不可用）",
                "tech_li_1": "Altera Cyclone V SOC（集成 ARM Cortex A9 双核处理器）",
                "tech_li_2": "1GB DDR3 RAM",
                "tech_li_3": "5 个总线端口，可通过可插拔收发器灵活分配",
                "tech_li_4": "每个总线端口上可用的总线通道为 2-6 个",
                "tech_li_5": "最多可支持 4 个 FlexRay 控制器（4 个 Bosch E-Ray 核心）",
                "tech_li_6": "总线端口上最多可支持 8 个 FlexRay 通道（4 个 A 通道和 4 个 B 通道）",
                "tech_li_7": "最多可支持 15 个 CAN-HS 总线通道",
                "tech_li_8": "最多可支持 10 个 CAN-FD 总线通道",
                "tech_li_9": "1GBit/s 以太网配置通道",
                "tech_li_10": "集成 WIFI 模块（b/n/g）"
            },
            l: {
                text: "FL3X Interface-L是FL3X通道系列中的中级解决方案，它提供了一个1000BASE-T上行端口，\n" +
                    "              用于连接记录仪或测量计算机。该通道既可以配合标准CMP或PLP记录协议用于数据记录设备，\n" +
                    "              也可以作为一个全功能的通道，支持读写操作，适用于我们提供的Linux和Windows驱动程序。",
                "desc_p_1": "FL3X Interface 系列提供了一种高度可变的汽车总线通道解决方案，适用于数据采集设备和个人电脑，通过可插拔物理层模块和 FPGA应用 支持当前和即将推出的总线和网络系统。",
                "desc_p_2": "FL3X Interface-L 具有一个 FPGA 用于托管总线系统和五个 FL3X Tiny3 插槽，可提供 FlexRay、CAN-HS/FD/SIC 和 LIN/SENT 通道。由于其可自由安装的微型插槽和 FPGA，可以通过任何现有或即将推出的 FL3X Tiny3 和通信通道进行扩展。",
                "desc_li_1": "1GBit/s 以太网和 WIFI 通道",
                "desc_li_2": "5 个可更换的 FL3X Tiny3 物理层插槽",
                "desc_li_3": "5 个 M9 端口",
                "desc_li_4": "8-42V",
                "desc_li_5": "LED 指示网络状态",
                "ad_li_1": "五个可插拔收发器模块（每个模块根据总线系统而定，通道数为 2-6）",
                "ad_li_2": "可用于 CAN-FS、CAN-FD、CAN-FD SIC、FlexRay、LIN、SENT*、K-Line*、RS232*、SPI* 的可插拔收发器模块",
                "ad_li_3": "紧凑的外形尺寸",
                "ad_li_4": "大多数总线通道的同步时间戳生成（分辨率高达 1μs）",
                "ad_li_5": "可配置的总线终端",
                "ad_li_6": "Bosch E-Ray IP 核心 CAN 控制器",
                "ad_li_7": "Bosch D-CAN IP 核心 CAN 控制器",
                "ad_li_8": "Bosch M-CAN IP 核心 CAN-FD 控制器",
                "ad_li_9": "英特尔三速以太网控制器",
                "ad_li_10": "V2.1 A FlexRay 协议规范",
                "ad_li_11": "V2.0 A/B CAN 协议规范",
                "ad_li_12": "ISO 11898-1:2015 CAN 协议规范",
                "ad_li_13": "Bosch CAN-FD 规范 1.0\n* （API 可能但目前尚不可用）",
                "tech_li_1": "Altera Cyclone V SOC (集成 ARM Cortex A9 双核 CPU)",
                "tech_li_2": "1GB DDR3 RAM",
                "tech_li_3": "5 个总线端口，可通过可插拔收发器灵活分配",
                "tech_li_4": "每个总线端口上可用 2-6 个总线通道",
                "tech_li_5": "最多 4 个 FlexRay 控制器（4 个 Bosch E-Ray 内核）",
                "tech_li_6": "每个总线端口上最多可用 8 个 FlexRay 控制器（4 个 A 通道和 4 个 B 通道）",
                "tech_li_7": "最多 15 个 CAN-HS 总线通道",
                "tech_li_8": "最多 10 个 CAN-FD 总线通道",
                "tech_li_9": "1GBit/s 以太网配置通道",
                "tech_li_10": "集成 WIFI 模块（b/n/g）"
            },
            s: {
                text: "FL3X Interface S是FL3X通道系列的入门解决方案，配备了一个1000Base-T上行端口，用于连接记录器或测量PC。\n" +
                    "              它可配合标准CMP或PLP记录协议用于数据记录设备，或者作为一个具备读写功能的全功能通道，\n" +
                    "              配合我们为Linux和Windows提供的驱动程序使用。",
                desc_1: "FL3X Interface 系列提供了一种高度灵活的汽车总线通道解决方案，适用于数据采集设备和个人计算机，\n" +
                    "            通过可插拔的物理层模块和应用FPGA，支持当前和未来的总线及网络系统。",
                desc_2: "FL3X Interface-S内置了FlexRay、CAN-HS/FD、LIN/SENT以及100BASE-T1通道，\n" +
                    "            并提供了一个FL3X Tiny3插槽，用于安装可插拔的收发器。借助于其灵活的Tiny插槽设计和FPGA技术，\n" +
                    "            该通道能够轻松扩展，支持现有及未来推出的各种FL3X Tiny3通信通道。",
                "ad_li_1": "一个可插拔的收发器模块（每个模块根据总线系统而定，通道数为 2-6）",
                "ad_li_2": "可用于 CAN-FS、CAN-FD、CAN-FD SIC、FlexRay、LIN、SENT*、KLine*、RS232*、SPI* 的可插拔收发器模块",
                "ad_li_3": "可插拔收发器模块 100BASE-T1、1000BASE-T 仅用于 Tap 模式",
                "ad_li_4": "紧凑的外形尺寸",
                "ad_li_5": "大多数总线通道的同步时间戳生成（分辨率高达 1μs）",
                "ad_li_6": "配置总线终端",
                "ad_li_7": "Bosch E-Ray IP 核心 FlexRay 控制器",
                "ad_li_8": "Bosch D-CAN IP 核心 CAN 控制器",
                "ad_li_9": "Bosch M-CAN IP 核心 CAN-FD 控制器",
                "ad_li_10": "英特尔三速以太网控制器",
                "ad_li_11": "V2.1 A FlexRay 协议规范",
                "ad_li_12": "V2.0 A/B CAN 协议规范",
                "ad_li_13": "ISO 11898-1:2015 CAN 协议规范",
                "ad_li_14": "Bosch CAN-FD 规范 1.0\n* （API 可能但目前尚不可用）",
                "tech_li_1": "Altera Cyclone V SOC（集成 ARM Cortex A9 双核处理器）",
                "tech_li_2": "1 GByte DDR3 RAM",
                "tech_li_3": "3 个 D-sub 总线端口，具有固定和可插拔的收发器",
                "tech_li_4": "每个端口上可拥有多达 6 个总线/网络通道",
                "tech_li_5": "支持的汽车总线/网络系统",
                "tech_li_6": "最多 4 个 FlexRay 控制器（Bosch E-Ray 核心）*",
                "tech_li_7": "每个端口上可用的 2 个 FlexRay 通道（2 个 A 通道和 2 个 B 通道）",
                "tech_li_8": "带有 2 个 FlexRay 核心的端口支持 SelfSync",
                "tech_li_9": "最多 6 个 CAN-FD 控制器*",
                "tech_li_10": "最多 8 个 CAN-HS 控制器*",
                "tech_li_11": "最多 3 个 100 MBit/s 以太网控制器*",
                "tech_li_12": "通过 RJ45 的 1 GBit/s 以太网配置通道",
                "tech_li_13": "* 可能有多种总线组合，请与我们联系以获取您所需的总线配置。"
            },
            pmc: {
                text: "Fl3X Interface PMC是一款用于汽车总线系统的PMC设计总线通道设备。该卡的8个总线通道可以通过物理层（\n" +
                    "              FL3X Tiny2）插槽配置为不同的总线系统。",
                desc: "FL3X Interface-PMC是一款采用PMC设计的汽车总线系统通道。该卡的8个总线通道可通过物理层（FL3X Tiny2）插槽配置为不同的总线系统。",
                "ad_li_1": "8 个交换总线通道",
                "ad_li_2": "CAN(-FD,-HS)FlexRay 和 100BASE-T1 的物理层可用",
                "ad_li_3": "所有 8 个总线通道的同步时间戳生成（分辨率 1μs）",
                "ad_li_4": "提供 PMC-Adapter 适配器：PCI、PCIe",
                "ad_li_5": "可用驱动程序：Windows、Linux、Xenomai",
                "ad_li_6": "众多的触发功能",
                "ad_li_7": "可配置终端电阻",
                "tech_li_1": "PMC 通道（PCI Mezzanine 卡）",
                "tech_li_2": "所有 8 个总线通道的同步时间戳生成（分辨率 1 μs）",
                "tech_li_3": "FL3X Tiny2 可互换的物理层插槽",
                "tech_li_4": "4* Binder 712 8pol 端口",
                "tech_li_5": "3.3V DC 和 5V DC 兼容的 PCI 电源供应",
                "tech_li_6": "LED 灯指示网络状态",
                "tech_li_7": "触发器 - 2x 输入/输出可配置",
                "tech_li_8": "可配置终端电阻",
                "tech_li_9": "包含分析软件 FLexAnalyzerV2",
                "tech_li_10": "Bosch E-Ray IP Core FLexRay 控制器",
                "tech_li_11": "Bosch D-CAN IP Core CAN 控制器",
                "tech_li_12": "Bosch M-CAN IP Core CAN-FD 控制器",
                "tech_li_13": "超过 IP 10/100/1000 MBit/s 以太网控制器",
                "tech_li_14": "V2.1 A FlexRay 协议规范",
                "tech_li_15": "V2.0 A/B CAN 协议规范",
                "tech_li_16": "ISO 11898-1:2015 CAN 协议规范",
                "tech_li_17": "Bosch CAN-FD 规范 1.0"
            },
            pxie: {
                text: "FL3X Interface PCIe/PXIe代表FL3X通道系列中的中端解决方案，采用PCIe和PXIe规格的接口结构，专为Logger或测量PC设计。\n" +
                    "              它能够作为一个全功能通道使用，支持读写操作，并且兼容我们为Linux、Windows或Xenomai开发的驱动程序。",
                desc_1: "FL3X Interface 系列代表了汽车总线通道技术的先进解决方案，专为数据采集设备和计算机系统设计。它利用可插拔的物理层模块和应用FPGA，\n" +
                    "            确保对当前及未来的总线和网络系统的全面支持。",
                desc_2: "FL3X Interface PCIe/PXIe搭载了专用FPGA，实现对多种总线系统的集成托管。它还配备了五个FL3X Tiny3插槽，支持灵活配置的收发器，\n" +
                    "            可提供包括FlexRay、CAN-HS/FD/SIC、LIN/SENT、10BASE-T1S、100BASE-TX及100/1000BASE-T1等多种通道。\n" +
                    "            借助其模块化设计和FPGA的强大功能，该通道能够适配任何现有或未来推出的FL3X Tiny3通讯通道，为用户提供了极高的扩展性和灵活性。",
                "ad_li_1": "五个可插拔的收发器模块（每个模块根据总线系统不同有 2-6 个通道）",
                "ad_li_2": "可用于 CAN-HS、CAN-FD、CAN-FD SIC、FlexRay、100BASE-TX、10BASE-T1S、100BASE-T1、1000BASE-T1、LIN、SENT*、K-Line*、RS232*、SPI* 的可插拔收发器模块",
                "ad_li_3": "紧凑的外形尺寸",
                "ad_li_4": "大多数总线通道同步时间戳生成（分辨率高达 1μs 和 1ns）",
                "ad_li_5": "可配置的总线终端",
                "ad_li_6": "Bosch E-Ray IP 核心 FlexRay 控制器",
                "ad_li_7": "Bosch D-CAN IP 核心 CAN 控制器",
                "ad_li_8": "Bosch M-CAN IP 核心 CAN-FD 控制器",
                "ad_li_9": "英特尔三速以太网以太网控制器",
                "ad_li_10": "V2.1 A FlexRay 协议规范",
                "ad_li_11": "V2.0 A/B CAN 协议规范",
                "ad_li_12": "ISO 11898-1:2015 CAN 协议规范",
                "ad_li_13": "Bosch CAN-FD 规范 1.0",
                "tech_li_1": "集成 Altera Cyclone V SOC（ARM Cortex-A9 双核 CPU）",
                "tech_li_2": "通过可插拔收发器灵活分配的 5 个总线端口 - 每个总线端口上可用的总线通道为 2-6 个",
                "tech_li_3": "最多 3 个 FlexRay 控制器（4 个 Bosch E-Ray 核心）*",
                "tech_li_4": "总线端口上最多可用 6 个 FlexRay 通道（3 个 A 通道和 3 个 B 通道）*",
                "tech_li_5": "在任何情况下，2 个 FlexRay 核心可以内部连接（SelfSync）",
                "tech_li_6": "最多 6 个 CAN-HS 总线通道*",
                "tech_li_7": "最多 6 个 CAN-FD 总线通道*",
                "tech_li_8": "1 个 100BASE-T1 总线通道 - 可以实现多种总线组合，请联系我们了解您所需的总线配置。"
            },
        },
        media: {
            base10: {
                text: "FL3X Media 10BASE-T1是一款可实现10BASE-T1与10BASE-TX之间相互转换的转换器。通过FL3X Media 10BASE-T1，\n" +
                    "              您可以将汽车以太网网络与标准PC连接，用于测试或诊断目的。",
                desc: "FL3X Media 10BASE-T1是一款用于10BASE-T1到10BASE-TX双向的转换器。通过FL3X Media 10BASE-T1，\n" +
                    "            可以将汽车以太网网络连接到标准PC，用于测试或诊断目的。它支持双方均以全双工模式运行，最高速度可达10 MBits。",
                "ad_li_1": "通过DIP开关可选择主/从模式",
                "ad_li_2": "使用Broadcom BCM898 10收发器进行100BASE-T1转换",
                "ad_li_3": "供电电压：8-48V DC",
                "ad_li_4": "状态LED指示灯",
                "ad_li_5": "用于10BASE-TX的RJ45接头",
                "ad_li_6": "用于10BASE-T1的D-Sub 9针公头接头",
                "ad_li_7": "IP20",
                "ad_li_8": "温度范围：-40°C至+85°C",
                "tech_li_1": "电气特性",
                "tech_li_2": "- 供电电压工作范围：+8.0V 至 +48.0V",
                "tech_li_3": "- 绝对最大值（非工作状态）：-60.0V 至 +60.0V",
                "tech_li_4": "- 供电电流（工作状态）：典型值：80mA",
                "tech_li_5": "物理特性",
                "tech_li_6": "- 端口：RJ45，D-Sub 9",
                "tech_li_7": "- 重量约：145g",
                "tech_li_8": "- 尺寸约为 L×W×H：92×65×28mm",
                "tech_li_9": "环境条件",
                "tech_li_10": "- 工作温度：-40°C 至 +85°C",
                "tech_li_11": "- 存储温度：-40°C 至 +85°C",
                "tech_li_12": "- 相对湿度：0% - 90% r.H.，不凝结"
            },
            base100: {
                text: "FL3X Media 100BASE-T1是一款可实现100BASE-T1与100BASE-TX之间相互转换的转换器。通过FL3X Media 100BASE-T1，\n" +
                    "              您可以将汽车以太网网络与标准PC连接，用于测试或诊断目的。",
                desc: "FL3X Media 100BASE-T1是一款用于100BASE-T1到100BASE-TX双向的转换器。通过FL3X Media 100BASE-T1，\n" +
                    "            可以将汽车以太网网络连接到标准PC，用于测试或诊断目的。它支持双方均以全双工模式运行，最高速度可达100 MBits。",
                "ad_li_1": "通过DIP开关可选择主/从模式",
                "ad_li_2": "使用Broadcom BCM898 10收发器进行100BASE-T1转换",
                "ad_li_3": "供电电压：8-48V DC",
                "ad_li_4": "状态LED指示灯",
                "ad_li_5": "用于100BASE-TX的RJ45接头",
                "ad_li_6": "用于100BASE-T1的D-Sub 9针公头接头",
                "ad_li_7": "IP20",
                "ad_li_8": "温度范围：-40°C至+85°C",
                "tech_li_1": "电气特性",
                "tech_li_2": "- 供电电压工作范围：+8.0V 至 +48.0V",
                "tech_li_3": "- 绝对最大值（非工作状态）：-60.0V 至 +60.0V",
                "tech_li_4": "- 供电电流（工作状态）：典型值：80mA",
                "tech_li_5": "物理特性",
                "tech_li_6": "- 端口：RJ45，D-Sub 9",
                "tech_li_7": "- 重量约：145g",
                "tech_li_8": "- 尺寸约为 L×W×H：92×65×28mm",
                "tech_li_9": "环境条件",
                "tech_li_10": "- 工作温度：-40°C 至 +85°C",
                "tech_li_11": "- 存储温度：-40°C 至 +85°C",
                "tech_li_12": "- 相对湿度：0% - 90% r.H.，不凝结"
            },
            base1000: {
                text: "FL3X Media 1000BASE-T1是一款用于1000BASE-T1到1000BASE-T双向转换器。通过FL3X Media 1000BASE-T1，\n" +
                    "              可以将汽车以太网网络连接到标准个人计算机进行测试或诊断。",
                desc: "FL3X Media 1000BASE-T1是用于100/1000BASE-T1到100/1000BASE-T双向转换器。通过FL3X Media 1000BASE-T1，\n" +
                    "            可以将汽车以太网网络连接到标准个人计算机进行测试或诊断。它支持双向全双工模式下高达1000 MBit/s的传输速率。",
                "tech_li_1": "通过 DIP 开关可选择主从模式",
                "tech_li_2": "通过 DIP 开关可选择 100 MBit/s 和 1000 MBit/s 模式",
                "tech_li_3": "A0 和 A2 模式自动选择",
                "tech_li_4": "Marvell 88Q21 12-A2-NYD2A000 收发器用于 1000BASE-T1 转换",
                "tech_li_5": "供电电压：8-48V DC",
                "tech_li_6": "状态 LED 灯",
                "tech_li_7": "用于 1000BASE-T 的 RJ45 接头",
                "tech_li_8": "用于 1000BASE-T1 的 D-Sub 9 针公头接头",
                "tech_li_9": "IP20",
                "tech_li_10": "温度范围：-40°C 至 +70°C",
                "tech_ad_li_1": "电气特性",
                "tech_ad_li_1_sub": [
                    "- 工作供电电压：+8.0V 至 +48.0V",
                    "- 绝对最大值（非工作状态）：-60.0V 至 +60.0V",
                    "- 工作电流（典型值）：140mA"
                ],
                "tech_ad_li_2": "物理特性",
                "tech_ad_li_2_sub": [
                    "- 端口：RJ45、D-Sub 9",
                    "- 重量（约）：145克",
                    "- 尺寸（约）：L×W×H 92×65×28mm"
                ],
                "tech_ad_li_3": "环境条件",
                "tech_ad_li_3_sub": [
                    "- 工作温度：-40°C 至 +70°C",
                    "- 存储温度：-40°C 至 +70°C",
                    "- 相对湿度：0% - 90% 相对湿度，无冷凝"
                ]
            },
        },
        switch: {
            base10: {
                text: "此交换机配备7个100/1000BASE-T1端口及一个支持2.5G/5G/10G速率的BASE-T1端口，进一步扩展了一个最高达10G的SFP+端口。\n" +
                    "              基于Marvell的高性能1000BASE-T1交换机芯片88Q6113构建，该设备能够通过Marvell提供的专业软件进行灵活配置，\n" +
                    "              以适应各种网络需求。",
                desc_1: "该交换机搭载了高性能ARM® Cortex M7 CPU及专用的片上内存，支持AVB协议如精确时间协议（PTP）以及安全防火墙，\n" +
                    "            以防御外部恶意攻击。交换机采用先进的路由引擎，支持千兆包的路由转发，无需CPU干预。",
                desc_2: "它提供了最高级别的安全性，通过深度包检测（DPI）技术、拒绝服务（DoS）引擎和可信启动功能，以保护车载网络安全。",
                "ad_li_1": "7 * 100/1000BASE-T1，使用 H-MTD 端口",
                "ad_li_2": "1 * 多千兆端口，最高可达 10G BASE-T1，使用 H-MTD 端口",
                "ad_li_3": "1 * SFP+ 端口",
                "ad_li_4": "Marvell 88Q6113 交换芯片",
                "ad_li_5": "完全符合 IEEE802.3",
                "ad_li_6": "802.1 AS PTP 支持主/从和透明时钟",
                "ad_li_7": "供电电压：8至48 V 直流",
                "ad_li_8": "温度范围：-40°C 至 +85°C",
                "ad_li_9": "集成 RTC（实时时钟）",
                "tech_li_1": "工作电压：+8.0V 至 +48.0V",
                "tech_li_2": "绝对最大电压（非工作状态）：-60.0V 至 +60.0V",
                "tech_li_3": "工作时电流：Typ: 670mA",
                "tech_li_4": "端口：1 * Binder 2pol. / 1 * SFP+ / 8 * H-MTD",
                "tech_li_5": "重量：约 625g",
                "tech_li_6": "尺寸：约 L * W * H 166 * 124 * 36 mm",
                "tech_li_7": "工作温度：-40°C 至 +85°C",
                "tech_li_8": "非工作温度：-40°C 至 +85°C",
                "tech_li_9": "存储温度：-40°C 至 +85°C",
                "tech_li_10": "IP-代码：IP20",
                "tech_li_11": "相对湿度：0% - 90% r.H.，非冷凝"
            },
            base:{
                text: "FL3X Switch 1000BASE-T1代表市场上最先进的1000BASE-T1汽车以太网交换机之一，特别是在其独特的过滤机制方面表现出色。\n" +
                    "              该交换机为每个端口提供了可定义的筛选条件，使其能够针对特定端口上的流量实施精确的阻断控制。",
                desc: "FL3X Switch 1000BASE-T1是市场上首批提供特殊过滤机制的1000BASE-T1交换机之一。该交换机可以为每个端口定义特定的标准，\n" +
                    "            从而允许阻止该端口上的某些流量。因此，该交换机在需要时可作为数据过滤器，可以灵活减少下游硬件的数据流量。所有功能均在硬件中实现，\n" +
                    "            因此该交换机具有出色的实时性能。",
                "tech_li_1": "7 * 100/1000BASE-T1 端口",
                "tech_li_2": "2 * SFP+ 端口",
                "tech_li_3": "Marvell 88Q6113 Switch 芯片",
                "tech_li_4": "完全符合 IEEE802.3 标准",
                "tech_li_5": "Harting T1 端口",
                "tech_li_6": "温度范围：-40°C 至 +85°C",
                "tech_li_7": "802.1 AS PTP 支持主/从模式和透明时钟",
                "tech_li_8": "集成 ARM Cortex M7 CPU",
                "tech_1": "工作电压：+8.0V 至 +48.0V",
                "tech_2": "绝对最大值（非运行状态）：-60.0V 至 +60.0V",
                "tech_3": "工作电流：Typ: 670mA",
                "tech_4": "连接：1 * Binder 2pol. / 2 * SFP+ / 7 * Harting T1",
                "tech_5": "重量约：625g",
                "tech_6": "尺寸约：L * W * H: 166 * 124 * 36 mm",
                "tech_7": "工作温度：-40°C 至 +85°C",
                "tech_8": "非运行温度：-40°C 至 +85°C",
                "tech_9": "存储温度：-40°C 至 +85°C",
                "tech_10": "IP 等级：IP20",
                "tech_11": "相对湿度：0% - 90% r. H.，不冷凝"
            }
        },
        system: {
            m: {
                text: "FL3X System-M 是 FL3X System家族中配置灵活度中等的产品。得益于其灵活的扩展性和模块化平台，FL3X System-M 可以完美地适应您的需求。",
                desc: "FL3X System-M 属于 FL3X System家族中的中端产品，其高性能专为千兆速率范围优化，对于总线系统的配置显示出高度的灵活性。\n" +
                    "            得益于其灵活的扩展能力和模块化平台，FL3X System-M 能够完美地适应您的具体需求。通过自由配置的组装，\n" +
                    "            用户可以选择包括 CAN、FlexRay 和汽车以太网等在内的多种通道配置。外部通道如1000BASE-T、USB和WiFi，\n" +
                    "            为数据传输和配件连接提供了多种方式。即便是大容量的数据存储设备也能无障碍连接，以便高效记录大量数据流。\n" +
                    "            因此，无论是实现高数据吞吐量的网关、配备众多ECU的残余总线仿真，还是记录多个数据流，FL3X System-M 都能轻松应对。",
                "tech_li_1": "Intel® Core™ i3 CPU，8GB RAM 和 128GB NVMe SSD",
                "tech_li_2": "最大性能和变化性",
                "tech_li_3": "支持 CAN-HS/FD、FlexRay、100BASE-T1、1000BASE-T1、2.5/5/10GBase-T1、100BASE-TX、1000BASE-T、LIN 和 SENT",
                "tech_li_4": "最多支持 2 个 FL3X Device-PCIe 或 2 个 FL3X Interface-PMC，每个最多支持 10 个 FL3X Tiny Slots（也可组合使用）",
                "tech_li_5": "最多支持 5 个 SFP(+) 端口",
                "tech_li_6": "用于配置和测量的 1000BASE-T 端口",
                "tech_li_7": "完全支持 FL3X Config",
                "tech_li_8": "支持 PTP/IEEE 1558v2 的 TimeSync",
                "tech_li_9": "用于大容量存储、摄像头或其他设备的 USB 端口*",
                "tech_li_10": "集成 WIFI 模块",
                "tech_li_11": "温度范围：-40°C 至 +70°C",
                "tech_li_12": "远程开/关机",
                "tech_li_13": "* 仅限 STAR 认证配件",
                "tech_1": "Intel® Core™ i3 CPU",
                "tech_2": "8GB RAM",
                "tech_3": "128GB NVMe SSD",
                "tech_4": "PTP/IEEE 1588V2 支持时间同步总线和配置端口",
                "tech_5": "ASAM CMP 1.0 和 PLP 用于测量*1",
                "tech_6": "通过 RJ45 的 1GBit/s 以太网配置和测量通道",
                "tech_7": "用于大容量存储、摄像头和其他组件的 USB 3.2 端口*2",
                "tech_8": "WIFI 端口",
                "tech_9": "广泛的工作温度范围：-40°C 至 +70°C",
                "tech_10": "*1 即将推出",
                "tech_11": "*2 仅限 STAR 认证配件"
            },
            xs:{
                text: "FL3X System-XS 是 FL3X System家族中的入门级产品，其特点在于极高的性价比和非常紧凑的尺寸。\n" +
                    "              在基础配置中，FL3X System-XS 配备了一个 CAN-HS 或 CAN-FD 通道，以及一个 LIN 和 SENT 通道。",
                desc: "FL3X System-XS 作为 FL3X System家族的入门产品，以其卓越的性价比和极其紧凑的体积而著称。\n" +
                    "            在基本配置中，FL3X System-XS 配备了一个 CAN-HS 或 CAN-FD 通道，以及一个 LIN 和 SENT 通道。因此，它非常适合作为总线通道、\n" +
                    "            迷你记录器以及执行剩余总线模拟。在 XS² 版本中，您将拥有两个 CAN、两个 LIN 和两个 SENT 通道，\n" +
                    "            这意味着它也非常适合作为这些总线系统的网关或总线操纵设备。汽车以太网版本 XS³ 还为您提供一个 100BASE-T1 通道。",
                "tech_li_1": "ARM Cortex-A72 64 位四核 CPU，1GHz",
                "tech_li_2": "1GByte RAM",
                "tech_li_3": "8GByte eMMC",
                "tech_li_4": "支持 CAN-HS、CAN-FD、LIN、SENT 和 100BASE-T1",
                "tech_li_5": "完全支持 FL3X Config",
                "tech_li_6": "用于汽车总线系统的 25 引脚 D-Sub 端口",
                "tech_li_7": "支持 PTP/IEEE1588v2 时间同步",
                "tech_li_8": "支持唤醒/休眠模式",
                "tech_li_9": "用于配置和测量的 1000BASE-T 端口",
                "tech_li_10": "用于大容量存储、摄像头或其他设备的 USB 端口*",
                "tech_li_11": "集成 WIFI 模块和可选 LTE 模块",
                "tech_li_12": "温度范围：-20°C 至 +60°C",
                "tech_li_13": "* 仅限 STAR 认证配件",
                "tech_1": "ARM Cortex-A72 64 位四核 CPU，1GHz",
                "tech_2": "1GB DDR4 RAM",
                "tech_3": "8GB eMMC 闪存",
                "tech_4": "支持 PTP/IEEE1588v2 的时间同步，适用于总线和配置端口",
                "tech_5": "ASAM CMP 1.0 和 PLP 用于测量*",
                "tech_6": "CAN-HS 和 CAN-FD 的唤醒/睡眠模式支持",
                "tech_7": "1GBit/s 以太网配置和测量通道，通过 RJ45 连接",
                "tech_8": "25 引脚 D-sub 端口，用于电源、总线和 DO/ADIO",
                "tech_9": "集成 WIFI 模块，支持 2.4GHz、5.0GHz IEEE 802.11 b/n/g/ac",
                "tech_10": "可选 LTE Cat 4 模块*，支持 LTE-TDD、LTE-FDD、HSPA+、GSM、GPRS 和 EDGe，下载速率最高可达 150 MBit/s，上传速率最高可达 50 MBit/s",
                "tech_11": "* 即将推出"
            },
        }
    }
}