import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import { i18n } from '@/i18n' //国际化

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  i18n, //使用国际化
  router
}).$mount('#app')
