<template>
  <div>
    <!-- footer -->
    <div class="footprint">
      <el-row :gutter="20" class="footer">
        <el-col :span="2">
          <h3></h3>
        </el-col>
        <el-col :span="7" class="footer-column">
          <h3>{{ $t('h.footer.address') }}</h3>
          <p>{{ $t('h.footer.address_t1') }}<br>
            {{ $t('h.footer.address_t2') }}<br>
            {{ $t('h.footer.address_t3') }}<br>
            {{ $t('h.footer.address_t4') }}<br>
            {{ $t('h.footer.address_t5') }}<br>
          </p>
        </el-col>
        <el-col :span="5" class="footer-column">
          <h3>{{ $t('h.footer.contact') }}</h3>
          <ul>
            <li>P: +86 21 8018 3540</li>
            <li>sales-ee@star-cooperation.cn</li>
            <li>www.star-cooperation.cn</li>
          </ul>
        </el-col>
        <el-col :span="4" class="footer-column">
          <h3>{{ $t('h.footer.company') }}</h3>
          <ul>
            <li>{{ $t('h.footer.company_t1') }}</li>
            <li>{{ $t('h.footer.company_t2') }}</li>
            <li>{{ $t('h.footer.company_t3') }}</li>
          </ul>
        </el-col>
        <el-col :span="4" class="footer-column">
          <h3>{{ $t('h.footer.legal') }}</h3>
          <ul>
            <li>{{ $t('h.footer.legal_t1') }}</li>
            <li>{{ $t('h.footer.legal_t2') }}</li>
            <li>{{ $t('h.footer.legal_t3') }}</li>
          </ul>
        </el-col>
      </el-row>
      <div class="footer-bottom">
        <p>Data Protection Policy</p>
        <p>© 2024 STAR COOPERATION. All rights reserved</p>
      </div>
    </div>

    <div class="footprint-mobile">
      <el-row :gutter="20" class="footer">
        <el-col :span="2">
          <h3></h3>
        </el-col>
        <el-col :span="10" class="footer-column">
          <h3>{{ $t('h.footer.address') }}</h3>
          <p>{{ $t('h.footer.address_t1') }}<br>
            {{ $t('h.footer.address_t2') }}<br>
            {{ $t('h.footer.address_t3') }}<br>
            {{ $t('h.footer.address_t4') }}<br>
            {{ $t('h.footer.address_t5') }}<br>
          </p>
        </el-col>
        <el-col :span="10" class="footer-column">
          <h3>{{ $t('h.footer.contact') }}</h3>
          <ul>
            <li>P: +86 21 8018 3540</li>
            <li>info@star-cooperation.cn</li>
            <li>www.star-cooperation.cn</li>
          </ul>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="footer">
        <el-col :span="2">
          <h3></h3>
        </el-col>
        <el-col :span="10" class="footer-column">
          <h3>{{ $t('h.footer.company') }}</h3>
          <ul>
            <li>{{ $t('h.footer.company_t1') }}</li>
            <li>{{ $t('h.footer.company_t2') }}</li>
            <li>{{ $t('h.footer.company_t3') }}</li>
          </ul>
        </el-col>
        <el-col :span="10" class="footer-column">
          <h3>{{ $t('h.footer.legal') }}</h3>
          <ul>
            <li>{{ $t('h.footer.legal_t1') }}</li>
            <li>{{ $t('h.footer.legal_t2') }}</li>
            <li>{{ $t('h.footer.legal_t3') }}</li>
          </ul>
        </el-col>
      </el-row>
      <div class="footer-bottom">
        <p>Data Protection Policy</p>
        <p>© 2024 STAR COOPERATION. All rights reserved</p>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'ImageCarousel',
  data() {
    return {
      isSidebarVisible: false,
      language: localStorage.getItem("lang") || "EN"
    }
  },
  methods: {}

};
</script>

<style scoped>
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(~@/assets/fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* footer */
.footprint {
  font-family: Montserrat, SF Pro SC, SF Pro Display, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
}

.footer {
  background-color: #333; /* Or any other color */
  color: #fff;
  padding: 40px 0;
  line-height: 1.5;
}

.footer-column {
  margin-bottom: 20px;
  align-items: center;
}

.footer-column h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.footer-column ul li:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  padding-top: 0;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 0.8rem;
  margin: 5px 0;
}

@media (max-width: 767px) {
  .footprint {
    display: none;
  }

  .footprint-mobile {
    margin: 0;
  }

  .footer-column {
    align-items: center;
    justify-content: center;
  }

}

@media (min-width: 768px) {
  .footprint-mobile {
    display: none;
  }
}

</style>
